import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";
import toast from "react-hot-toast";

// Define the shape of the form data
interface FormData {
  title: string;
  level: string;
  key: string;
  check_exist: string;
  criticality: number;
}

interface CheckData {
  key: string;
  level: string;
  check_exist: string;
  criticality: string;
}

interface DataItem {
  user: number;
  external_id: string;
  title: string;
  check_data: CheckData;
}

interface CheckKeyData {
  [key: string]: [string, string][]; // Example structure where each level is an array of tuples
}

function CustomCheckRule() {
  const initialFormData: FormData = {
    title: "",
    level: "Campaign", // Default level
    key: "",
    check_exist: "yes",
    criticality: 1,
  };
  const [isFormVisible, setIsFormVisible] = useState(false); // State to toggle form visibility
  const [allChecks, setAllChecks] = useState<FormData[]>([]);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [checkkey, setCheckKey] = useState<CheckKeyData>({});
  const [customChecks, setcustomChecks] = useState<DataItem[]>([]);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const renderChecks = (): string[] => {
    const lowerLevel = formData.level.toLowerCase();
    // Check if checkkey and formData.level are valid and exist
    if (checkkey && formData.level && checkkey[lowerLevel]) {
      // Map over checkkey[formData.level] and extract the second value of each tuple
      return checkkey[lowerLevel].map((data: any) => data);
    }
    return []; // Return empty array if checkkey or formData.level is not valid
  };

  const getCheckKey = async () => {
    try {
      // Make an axios call to get the check key data
      const response = await axiosInstance.get("/checks/get-check-level");

      // Set the checkkey state with the response data
      setCheckKey(response.data.data["check_data"]);
    } catch (error) {
      console.error("Error fetching check key data:", error);
    }
  };

  const getCustomChecks = async () => {
    try {
      // Make an axios call to get the check key data
      const response = await axiosInstance.get(
        "/checks/custom-quality-checks/"
      );

      // Set the customchecks state with the response data
      setcustomChecks(response.data.data);
    } catch (error) {
      console.error("Error fetching check key data:", error);
    }
  };

  useEffect(() => {
    getCheckKey();
    getCustomChecks();
  }, [0]);

  const handleItemClick = (index: number) => {
    // Toggle the item: if clicked again, collapse; otherwise, expand
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  // Function to toggle form visibility
  const toggleFormVisibility = () => setIsFormVisible(!isFormVisible);

  // Function to handle form input changes with proper typing
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // Type assertion: ensure `name` is a key of FormData
    if (name in formData) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (name === "level") {
        renderChecks();
      }
    }
  };

  // Function to handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setAllChecks((prevChecks) => [...prevChecks, formData]);
    // Handle form submission logic, such as API calls
    setIsFormVisible(false); // Hide the form after submission
    setFormData(initialFormData);
  };

  const RunChecks = async (e: any) => {
    try {
      const response = await axiosInstance.get("/checks/run/checks");

      if (response.status === 200) {
        toast.success("Running checks please check after 15 min.");
      } else {
        toast.error("Something went wrong. please contact developer");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error Fetching advertisers, please try again."
      );
    }
  };

  const deleteCustomCheck = async (external_id: string) => {
    try {
      const response = await axiosInstance.delete(
        `/checks/custom-quality-checks/${external_id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("deleted successfully");
      }
      getCustomChecks();
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error when deleting custom check, please try again."
      );
    }
  };

  const CreateChecks = async () => {
    if (allChecks?.length === 0) {
      toast.error("Please add some checks.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        "/checks/custom-quality-checks/",
        {
          title: "Dummy title",
          check_data: allChecks,
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Checks Created successfully");
      }
      setAllChecks([]);
      getCustomChecks();
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error fetching Advertisers, please try again."
      );
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
          <h1 className="text-4xl font-serif text-indigo-700 mb-6">
            Create Your Custom Check
          </h1>
          {/* Button to toggle form visibility */}
          <button
            onClick={toggleFormVisibility}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {isFormVisible ? "Hide Form" : "Add Check"}
          </button>
          <button
            onClick={CreateChecks}
            className="px-4 py-2 my-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Create final Check
          </button>
          <button
            onClick={RunChecks}
            className="px-4 py-2 my-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Run checks
          </button>

          {/* Form displayed below the button when isFormVisible is true */}
          {isFormVisible && (
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mt-4">
              <form onSubmit={handleSubmit}>
                <h2 className="text-lg font-bold mb-4">Custom Check Form</h2>

                {/* Check Title */}
                <div className="mb-4">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Check Title
                  </label>
                  <input
                    id="title"
                    name="title"
                    type="text"
                    required
                    value={formData.title}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter check title"
                  />
                </div>

                {/* Check Level */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Check Level
                  </label>
                  <select
                    name="level"
                    value={formData.level}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="Campaign">Campaign</option>
                    <option value="Insertion_Level">Insertion_Level</option>
                    <option value="Line_Item">Line_Item</option>
                  </select>
                </div>

                {/* Check Key */}
                <div className="mb-4">
                  <label
                    htmlFor="key"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Check Type
                  </label>
                  <select
                    name="key"
                    value={formData.key}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value={"select Check Value"}>
                      select Check Value
                    </option>
                    {renderChecks().map((data, index) => (
                      <option key={index} value={data[0]}>
                        {data[1]}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Check Criticallity  */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Criticalty
                  </label>
                  <select
                    name="criticality"
                    value={formData.criticality}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="1">Threat</option>
                    <option value="2">Warning</option>
                    <option value="4">Anomaly</option>
                    <option value="3">Optimization</option>
                  </select>
                </div>

                {/* Check Existence */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Check Exist
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="inline-flex items-center mr-4">
                      <input
                        type="radio"
                        name="check_exist"
                        value="yes"
                        checked={formData.check_exist === "yes"}
                        onChange={handleInputChange}
                        className="form-radio text-indigo-600 border-gray-300"
                      />
                      <span className="ml-2 text-gray-700">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="check_exist"
                        value="no"
                        checked={formData.check_exist === "no"}
                        onChange={handleInputChange}
                        className="form-radio text-indigo-600 border-gray-300"
                      />
                      <span className="ml-2 text-gray-700">No</span>
                    </label>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="text-right">
                  <button
                    type="submit"
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <div className="p-6 bg-gray-50 min-h-screen">
        <h4 className="text-2xl font-semibold mb-4 text-gray-800">
          Custom Checks You Have Created
        </h4>
        <div className="space-y-4">
          {customChecks?.map((data: DataItem, index: number) => {
            const isExpanded = expandedIndex === index;
            return (
              <div
                key={index}
                className={`p-4 bg-white shadow-md rounded-lg border border-gray-200 cursor-pointer transition-all duration-300 ${
                  isExpanded ? "h-auto" : "h-20 overflow-hidden"
                }`}
                onClick={() => handleItemClick(index)}
              >
                <div className="flex items-center justify-between">
                  <h4 className="text-lg font-medium text-gray-700 mb-2">
                    {data.title}
                  </h4>
                  <button
                    onClick={() => deleteCustomCheck(data.external_id)}
                    className="border-2 border-black rounded-lg p-2 bg-red-300"
                  >
                    {" "}
                    Delete{" "}
                  </button>
                </div>
                {isExpanded && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <strong>Key:</strong> {data.check_data.key || "N/A"}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Level:</strong> {data.check_data.level}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Check Exist:</strong>{" "}
                      {data.check_data.check_exist}
                    </p>
                    <p className="text-sm text-gray-500">
                      <strong>Criticality:</strong>{" "}
                      {data.check_data.criticality}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CustomCheckRule;
