import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { API_BASE_URL } from "../constants";

interface AuthContextType {
  accessToken: string | null;
  refreshToken: string | null;
  fullName: string | null;
  setTokensAndName: (access: string, refresh: string, fullName: string) => void;
  refreshAccessToken: () => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    Cookies.get("accessToken") || null
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    Cookies.get("refreshToken") || null
  );

  const [fullName, setFullName] = useState<string | null>(
    Cookies.get("fullName") || null
  );

  useEffect(() => {
    if (accessToken && isTokenExpired(accessToken)) {
      refreshAccessToken();
    }
  }, [accessToken]);

  const setTokensAndName = (
    access: string,
    refresh: string,
    fullName: string
  ) => {
    setAccessToken(access);
    setRefreshToken(refresh);
    setFullName(fullName);
    Cookies.set("accessToken", access, { expires: 1 / 24 }); // expires in 1 hour
    Cookies.set("refreshToken", refresh, { expires: 1 }); // expires in 24 hours
    Cookies.set("fullName", fullName, { expires: 1 }); // expires in 24 hours
  };

  const refreshAccessToken = async () => {
    if (refreshToken) {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/token/refresh/`,
          { refresh: refreshToken },
          { headers: { "Content-Type": "application/json" } }
        );
        const newAccessToken = response.data.data.access;
        setAccessToken(newAccessToken);
        Cookies.set("accessToken", newAccessToken, { expires: 1 / 24 });
      } catch (err) {
        logout();
      }
    } else {
      logout();
    }
  };

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setFullName(null);
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("fullName");
    window.location.href = "/login";
  };

  const isTokenExpired = (token: string) => {
    // Implement token expiration check
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload.exp * 1000 < Date.now();
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        refreshToken,
        fullName,
        setTokensAndName,
        refreshAccessToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
