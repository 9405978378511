import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { EMAIL_PATTERN } from "../constants/index";
import { useIdentifier } from "../context/IdentifierContext";
import atomic_adds_logo from "../assets/atomic_adds_logo.webp";
import background_image from "../assets/login_background.webp";
import { API_BASE_URL } from "../constants/index";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const navigate = useNavigate();
  const { setIdentifier } = useIdentifier();

  useEffect(() => {
    // Check cookies for tokens
    const accessToken = Cookies.get("accessToken");
    const refreshToken = Cookies.get("refreshToken");

    if (accessToken && refreshToken) {
      navigate("/connectors-library");
    }
  }, [navigate]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Validate email format
    setIsEmailValid(EMAIL_PATTERN.test(emailValue));
  };

  const handleContinueClick = async () => {
    setError(null);
    if (isEmailValid) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${API_BASE_URL}/otp/`,
          { email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const identifier = response.data?.data?.identifier;

        if (identifier) {
          setIdentifier(identifier);
          navigate("/login-verification", { state: { email, identifier } });
        } else {
          setError("Failed to receive OTP identifier. Please try again.");
        }
      } catch (err: any) {
        if (err.response) {
          setError(
            err.response.data?.message ||
              "Failed to send OTP. Please try again."
          );
        } else if (err.request) {
          setError("No response received from the server. Please try again.");
        } else {
          setError("An error occurred. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please enter a valid email.");
    }
  };
  return (
    <div
      className="flex items-center justify-center min-h-screen relative bg-cover bg-center"
      style={{ backgroundImage: `url(${background_image})` }}
    >
      <div className="relative z-10 bg-white pt-12 pb-[65px] px-[70px] shadow-lg w-[480px]">
        <div className="flex justify-center mb-4">
          <img
            src={atomic_adds_logo}
            alt="AtomicAds Icon"
            className="w-16 h-16"
          />
        </div>

        <h1 className="text-center text-2xl font-extrabold text-gray-900 mb-2">
          Welcome to AtomicAds
        </h1>

        <p className="text-center text-sm text-gray-500 mb-6">
          Please enter your credentials to access your dashboard.
        </p>

        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          className="w-full px-4 py-2 border mb-4 focus:outline-none focus:ring-2 focus:ring-green-600"
        />
        {error && (
          <p className="text-center text-red-500 mb-4 text-xs">{error}</p>
        )}

        <button
          className={`w-full text-white py-2 ${
            isEmailValid ? "bg-green-700 hover:bg-green-800" : "bg-gray-300"
          } transition duration-200 relative`}
          onClick={handleContinueClick}
          disabled={!isEmailValid || loading}
        >
          {loading ? (
            <div className="flex justify-center items-center">
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4h-4z"
                />
              </svg>
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </div>
    </div>
  );
};

export default Login;
