import React, { useEffect, useState } from "react";
import MultiSelectDropdown from "../MultiSelectDropdown";
import SingleSelectDropdown from "../SingleSelectDropdown";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBack, IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../statusIndicator/loader";
import GreenTick from "../statusIndicator/greenTick";
import CrossMark from "../statusIndicator/cross";
import { getIntegerFromString } from "../../utils/getIntegerFromStr";
import ScheduleSettings from "../SchedulerSettings";

interface SchedulingUserInput {
  interval?: string;
  period?: string;
  minute?: number;
  days_of_week?: string[];
  hour?: number;
  day?: number;
  month?: number;
  time_zone?: string;
}

interface userInputProps {}

const UserInputForVistar: React.FC<userInputProps> = () => {
  // State for input fields
  const [configurationName, setConfigurationName] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [clientSecretKey, setClientSecretKey] = useState<string>("");
  const [accountId, setAccountId] = useState<string>("");

  // State for checkboxes
  const [atomicAdsChecked, setAtomicAdsChecked] = useState<boolean>(true);
  const [warehouseChecked, setWarehouseChecked] = useState<boolean>(false);

  // State for SingleSelectDropdowns
  const [reportType, setReportType] = useState<string | null>("");
  const [historicalSyncTimeFrame, setHistoricalSyncTimeFrame] = useState<
    string | null
  >("");

  // State for MultiSelectDropdown
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [fetchedAccounts, setFetchedAccounts] = useState<
    { id: string; name: string }[]
  >([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState<boolean>(false);

  // Authorization tokens
  const { accessToken } = useAuth();

  // Navigation hook
  const navigate = useNavigate(); // Use useNavigate for navigation

  // State for authorization
  const [loading, setLoading] = useState<boolean>(false);
  const [authorizationStatus, setAuthorizationStatus] = useState<
    "success" | "error" | null
  >(null);

  // for save and test button activation
  const [isSaveAndTestActive, setIsSaveAndTestActive] =
    useState<Boolean>(false);

  // Additional loading state for Save & Test
  const [isSaving, setIsSaving] = useState<boolean>(false);

  //external_id
  const [externalId, setExternalId] = useState<string>("");

  // Determine if fields should be disabled based on authorization status
  const isAuthorized = authorizationStatus === "success";

  // scheduler
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);
  const [schedulingUserInput, setSchedulingUserInput] =
    useState<SchedulingUserInput>({});
  const [schedulePreview, setSchedulePreview] = useState<string>("");

  useEffect(() => {
    if (schedulePreview !== "") {
      setOpenSchedule(false);
    }
  }, [schedulePreview]);

  // Function to check if all required fields are filled
  const isFormComplete = () => {
    return (
      selectedAccounts.length > 0 &&
      reportType !== null &&
      historicalSyncTimeFrame !== null &&
      schedulingUserInput.time_zone
    );
  };

  // Function to handle button click for authorizing
  const handleAuthorizeConnection = async () => {
    setLoading(true);
    setAuthorizationStatus(null);

    try {
      const response = await axiosInstance.post(
        `/connector/vistar-connector/`,
        {
          configuration_name: configurationName,
          client_id: clientId,
          client_secret: clientSecretKey,
          account_id: accountId,
        }
      );

      if (response.status === 200) {
        setAuthorizationStatus("success");
        setIsSaveAndTestActive(true);
        setExternalId(response.data.data.external_id);
      } else {
        setAuthorizationStatus("error");
        setIsSaveAndTestActive(false);
      }
    } catch (error) {
      console.error("Error authorizing connection:", error);
      setAuthorizationStatus("error");
      setIsSaveAndTestActive(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (externalId !== "") {
      getAccounts();
    }
  }, [externalId]);

  const getAccounts = async () => {
    setIsLoadingAccounts(true);
    try {
      const response = await axiosInstance.get(
        `/connector/get-vistar-advertisers/${externalId}/`
      );
      if (response.status === 200) {
        setFetchedAccounts(response.data.data.advertisers);
      }
    } catch (error) {
      toast.error("Error while fetching accounts details");
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const handleOnClickSave = async () => {
    if (!isFormComplete()) {
      toast.error("Fill all the fields");
      return;
    }

    setIsSaving(true);

    const requestData = {
      configuration_name: configurationName,
      client_id: clientId,
      client_secret: clientSecretKey,
      account_id: accountId,
      selected_accounts: selectedAccounts,
      report_type: reportType,
      historical_data_range: getIntegerFromString(historicalSyncTimeFrame),
      external_id: externalId,
      schedule_settings: schedulingUserInput,
    };

    try {
      const response = await axiosInstance.post(
        `/connector/vistar-check-config/`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Save & Test successful!");
        navigate("/validate-connector", { state: { page: "vistar" } });
      } else {
        toast.error("Error saving and testing, please try again.");
      }
    } catch (error) {
      console.error("Error saving and testing:", error);
      toast.error("Error saving and testing, please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (reportType) {
      setHistoricalSyncTimeFrame("");
    }
  }, [reportType]);

  return (
    <div className="mb-[250px]">
      <div onScroll={(e) => e.stopPropagation()}>
        <div className="mt-4 flex flex-col">
          {/* Configuration Name */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Configuration Name</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12"
              value={configurationName}
              onChange={(e) => setConfigurationName(e.target.value)}
              disabled={isAuthorized}
            />
          </div>

          {/* Destination */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Destination</h3>
            <div className="flex items-center gap-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="atomic-ads"
                  className="border outline-none p-3 w-4 h-4"
                  checked={atomicAdsChecked}
                  onChange={() => setAtomicAdsChecked(!atomicAdsChecked)}
                  disabled={isAuthorized}
                />
                <label htmlFor="atomic-ads" className="ml-2 text-sm">
                  Atomic Ads
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="warehouse"
                  className="border outline-none p-3 w-4 h-4"
                  checked={warehouseChecked}
                  disabled
                />
                <label
                  htmlFor="warehouse"
                  className="ml-2 text-sm text-gray-500"
                >
                  Warehouse
                </label>
              </div>
            </div>
          </div>

          {/* Client Id */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Client Key</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              disabled={isAuthorized}
            />
          </div>

          {/* Client Secret Key */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Client Secret</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12"
              value={clientSecretKey}
              onChange={(e) => setClientSecretKey(e.target.value)}
              disabled={isAuthorized}
            />
          </div>

          {/* Account Id */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Account Id</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12"
              value={accountId}
              onChange={(e) => setAccountId(e.target.value)}
              disabled={isAuthorized}
            />
          </div>

          {/* Validate Credentials Button */}
          <button
            className={`border-[0.75px] ${
              isAuthorized ||
              !configurationName ||
              !clientId ||
              !clientSecretKey ||
              !accountId
                ? "border-gray-300 text-gray-300"
                : "border-[#286043] text-[#286043]"
            } text-sm font-semibold py-[6px] px-3 w-fit mb-6 flex items-center`}
            onClick={handleAuthorizeConnection}
            disabled={
              isAuthorized ||
              !configurationName ||
              !clientId ||
              !clientSecretKey ||
              !accountId
            }
          >
            {loading && (
              <>
                <Loader />
                Authorizing...
              </>
            )}
            {authorizationStatus === "success" && !loading && (
              <>
                <GreenTick />
                Authorized
              </>
            )}
            {authorizationStatus === "error" && !loading && (
              <>
                <CrossMark />
                Authorization Failed, Retry
              </>
            )}
            {!loading &&
              authorizationStatus !== "success" &&
              authorizationStatus !== "error" && <>Authorize</>}
          </button>

          {/* Account */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Account</h3>
            {isLoadingAccounts ? (
              <div className="flex items-center text-xs">
                <Loader />
                Loading Accounts...
              </div>
            ) : (
              <MultiSelectDropdown
                items={fetchedAccounts.map((item) => item.name)}
                selectedItems={selectedAccounts}
                setSelectedItems={setSelectedAccounts}
                disabled={!isAuthorized || isLoadingAccounts}
              />
            )}
          </div>

          {/* Report Type */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Report Type</h3>
            <SingleSelectDropdown
              items={["Campaign Report", "Publisher Report"]}
              selectedItem={reportType}
              setSelectedItem={setReportType}
              disabled={!isAuthorized}
            />
          </div>

          {/* Historical Sync Time Frame */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">
              Historical Sync Time Frame
            </h3>
            <SingleSelectDropdown
              items={
                reportType === "Publisher Report"
                  ? ["7 Days", "15 Days", "30 Days"]
                  : reportType === "Campaign Report"
                  ? ["30 Days", "60 Days", "90 Days"]
                  : []
              }
              selectedItem={historicalSyncTimeFrame}
              setSelectedItem={setHistoricalSyncTimeFrame}
              disabled={!isAuthorized || !reportType}
            />
          </div>

          {/* Scheduler Setting */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Schedule Setting</h3>
            <div className="flex gap-4 items-center">
              <button
                className={`text-sm border border-gray-300 p-2 ${
                  !isAuthorized ? "bg-gray-200" : "bg-white"
                }`}
                onClick={() => setOpenSchedule((prev) => !prev)}
                disabled={!isAuthorized}
              >
                +
              </button>
              <p>{schedulePreview}</p>
            </div>
          </div>
        </div>
      </div>

      {openSchedule && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className="bg-white shadow-lg min-w-[413px] border border-gray-300"
            style={{
              boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
            }}
          >
            <div className="pt-6 px-8 pb-6">
              <div className="flex justify-end">
                <button
                  onClick={() => setOpenSchedule((prevValue) => !prevValue)}
                  className=""
                >
                  <IoClose />
                </button>
              </div>
              <ScheduleSettings
                setSchedulingUserInput={setSchedulingUserInput}
                setSchedulePreview={setSchedulePreview}
              />
            </div>
          </div>
        </div>
      )}
      <div className="fixed bottom-0 left-[100px] right-0 bg-gray-100 border-t border-gray-300 py-8 flex justify-between items-center px-6 z-20 overflow-hidden">
        <Link
          to="/connectors-library"
          className=" flex items-center gap-2"
          onClick={() => setIsSaveAndTestActive(false)}
        >
          <IoChevronBack size={16} />
          <span className="text-base">Back</span>
        </Link>
        <div className="flex gap-2">
          <button
            className={`border-[0.75px] ${
              isSaveAndTestActive
                ? "border-[#286043] bg-[#286043]"
                : "bg-gray-300 border-gray-300"
            } text-white text-sm font-semibold py-[6px] px-3`}
            disabled={!isSaveAndTestActive || isSaving}
            onClick={handleOnClickSave}
          >
            {isSaving ? (
              <div className="flex gap-2 items-center">
                <Loader loaderColor="text-white" />
                Saving & Testing...
              </div>
            ) : (
              "Save & Test"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInputForVistar;
