import React from "react";

interface LoaderProps {
  loaderColor?: string;
}

const Loader: React.FC<LoaderProps> = ({ loaderColor }) => {
  const loaderTextColor = loaderColor ? loaderColor : "text-[#286043]";
  return (
    <svg
      className={`animate-spin h-5 w-5 ${loaderTextColor} mr-2`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4h-4z"
      />
    </svg>
  );
};

export default Loader;
