import React, { createContext, useContext, useState, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { IS_ADMIN } from "../constants";

interface ProfileContextType {
  isAdmin: boolean;
  toggleProfile: () => void;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

interface ProfileProviderProps {
  children: ReactNode;
}

export const ProfileProvider: React.FC<ProfileProviderProps> = ({
  children,
}) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(IS_ADMIN);
  const navigate = useNavigate();

  const toggleProfile = () => {
    setIsAdmin((prev) => !prev);
    navigate("/products");
  };

  return (
    <ProfileContext.Provider value={{ isAdmin, toggleProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }
  return context;
};
