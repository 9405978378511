import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import opportunities_logo from "../assets/opportunities_logo.png";
import Sidebar from "../components/Sidebar";
import page_layout from "../json/pageJson/myConnectors.json";
import NoActionsFound from "../components/noActionsFound";
import SixColumnsTable from "../components/SixColumnsTable";
import toast from "react-hot-toast";
import Skeleton from "../components/Skeleton";
import DialogComponent from "../components/dialogComponent";
import { RiDeleteBinLine, RiPauseLargeFill } from "react-icons/ri";
import axiosInstance from "../utils/axiosInstance";
import { VscDebugContinueSmall } from "react-icons/vsc";
import UserAvatar from "../components/userAvatar/UserAvatar";

const MyConnector: React.FC = () => {
  const [connectorsTableData, setConnectorsTableData] = useState<any[]>([]);
  const [connectorsTableHeader, setConnectorsTableHeader] = useState<string[]>(
    []
  );
  const [selectedConnectorId, setSelectedConnectorId] = useState<string>("");
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [selectedExternalId, setSelectedExternalID] = useState<string>("");
  const [pauseContinueLoader, setPauseContinueLoader] =
    useState<boolean>(false);
  const [showContinuePopup, setShowContinuePopup] = useState<boolean>(false);
  const [showPausePopup, setShowPausePopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    } else {
      getMyConnectorsData();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  const getMyConnectorsData = async () => {
    try {
      const response = await axiosInstance.get(`/connector/get-all-accounts/`);
      if (response.status === 200) {
        setConnectorsTableHeader(response.data.data.headers);
        setConnectorsTableData(response.data.data.data);
      } else {
        throw new Error("Unexpected response while fetching partners");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error fetching my connectors, please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const tableHeadingLayout = {
    heading1: connectorsTableHeader[0],
    heading2: connectorsTableHeader[1],
    heading3: connectorsTableHeader[2],
    heading4: connectorsTableHeader[3],
    heading5: connectorsTableHeader[4],
    heading6: connectorsTableHeader[5],
  };

  const handleDeleteConnector = (id: string) => {
    setSelectedConnectorId(id);
    setShowDeletePopup(true);
  };

  const handleOnConfirmDelete = async () => {
    toast.success("Deleting Connector...");
    try {
      const response = await axiosInstance.delete(
        `/connector/delete-account/${selectedConnectorId}/`
      );
      if (response.status === 200) {
        toast.success("Connector Deleted Successfully");
        setConnectorsTableData((prevData) => {
          const newConnectorList = prevData.filter(
            (connector) => connector.external_id !== selectedConnectorId
          );
          return newConnectorList;
        });
        setSelectedConnectorId("");
        setShowDeletePopup(false);
      } else {
        toast.success("Connector Not Deleted, Try Again");
        setSelectedConnectorId("");
        setShowDeletePopup(false);
        throw new Error("Unexpected response while fetching partners");
      }
    } catch (error: any) {
      setSelectedConnectorId("");
      setShowDeletePopup(false);
      toast.error(
        error.response?.data?.message ||
          "Error deleting this connector, please try again."
      );
    } finally {
      setSelectedConnectorId("");
      setShowDeletePopup(false);
    }
  };

  const handleOnDeclineDelete = () => {
    setSelectedConnectorId("");
    setShowDeletePopup(false);
  };

  const handleContinuePauseBtnClick = (
    btnText: string,
    selectedExternal_id: string
  ) => {
    setSelectedExternalID(selectedExternal_id);
    if (btnText.toLowerCase() === "resume") {
      setShowContinuePopup(true);
    } else if (btnText.toLowerCase() === "pause") {
      setShowPausePopup(true);
    } else {
      toast.error("Button text should be pause or resume only");
    }
  };

  const handleDeclineContinue = () => {
    setShowContinuePopup(false);
    setSelectedExternalID("");
  };

  const handleDeclinePause = () => {
    setShowPausePopup(false);
    setSelectedExternalID("");
  };

  const handleOnConfirmContinue = async () => {
    setShowContinuePopup(false);
    setPauseContinueLoader(true);
    try {
      const response = await axiosInstance.put(
        `/connector/continue/connector/${selectedExternalId}`
      );
      if (response.status === 200) {
        getMyConnectorsData();
      } else {
        throw new Error("Unexpected response while resuming this connector");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error resuming the selected connector, please try again."
      );
    } finally {
      setPauseContinueLoader(false);
    }
  };

  const handleOnConfirmPause = async () => {
    setShowPausePopup(false);
    setPauseContinueLoader(true);
    try {
      const response = await axiosInstance.put(
        `/connector/pause/connector/${selectedExternalId}`
      );
      if (response.status === 200) {
        getMyConnectorsData();
      } else {
        throw new Error("Unexpected response while pausing this connector");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error pausing the selected connector, please try again."
      );
    } finally {
      setPauseContinueLoader(false);
    }
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="my connectors" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <img
              src={opportunities_logo}
              alt="opportunities logo"
              className="w-[17.5px] h-[17.5px]"
            />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              {page_layout.myConnector_heading}
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              {page_layout.myConnector_heading_description}
            </p>
          </div>
        </div>

        {/* Connectors Section */}
        {loading ? (
          <Skeleton />
        ) : connectorsTableData.length > 0 ? (
          <div>
            <p className="text-gray-900 text-base leading-5 text-left font-bold pt-6 pb-6">
              {page_layout.myConnector_liveConnectors_heading}{" "}
              {connectorsTableData.length > 0
                ? `(${connectorsTableData.length})`
                : ""}
            </p>

            <div className="border-[0.75px] border-gray-300 py-6 px-4">
              <SixColumnsTable
                table_layout={tableHeadingLayout}
                table_data={connectorsTableData}
                handleDeleteConnector={handleDeleteConnector}
                handleContinuePauseBtnClick={handleContinuePauseBtnClick}
                pauseContinueLoader={pauseContinueLoader}
                selectedExternalId={selectedExternalId}
              />
            </div>
          </div>
        ) : (
          <NoActionsFound
            sectionName={page_layout.myConnector_liveConnectors_heading}
            errorHeading="No connectors found!"
            showActionButton={true}
            errorDescription="There are no connectors available, Please retry or add new connectors."
            actionButtonText="Add new connector"
            actionButtonRedirection=""
          />
        )}
        {showDeletePopup && (
          <DialogComponent
            closeDialog={handleOnDeclineDelete}
            dialog_logo={RiDeleteBinLine}
            dialog_heading="Are you sure you want to permanently delete this connector?"
            dialogSuccessAction={handleOnConfirmDelete}
            successBtnText="Yes, Delete it"
            failureBtnText="No, cancel"
            showNote={false}
            action_type="delete"
          />
        )}
        {showContinuePopup && (
          <DialogComponent
            closeDialog={handleDeclineContinue}
            dialog_logo={VscDebugContinueSmall}
            dialog_heading="Are you sure you want to resume this connector?"
            dialogSuccessAction={handleOnConfirmContinue}
            successBtnText="Yes, Resume it"
            failureBtnText="No, cancel"
            showNote={false}
            action_type="continue"
          />
        )}
        {showPausePopup && (
          <DialogComponent
            closeDialog={handleDeclinePause}
            dialog_logo={RiPauseLargeFill}
            dialog_heading="Are you sure you want to pause this connector?"
            dialogSuccessAction={handleOnConfirmPause}
            successBtnText="Yes, Pause it"
            failureBtnText="No, cancel"
            showNote={false}
            action_type="delete"
          />
        )}
      </div>
    </div>
  );
};

export default MyConnector;
