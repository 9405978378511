const CHIPS_COLORS = {
  threats: {
    borderColor: "#F01F1F",
    backgroundColor: "#f4e1e1",
    textColor: "#F01F1F",
  },
  warnings: {
    borderColor: "#C4AB2D",
    backgroundColor: "#f6f1db",
    textColor: "#C4AB2D",
  },
  anomalies: {
    borderColor: "#FF9E44",
    backgroundColor: "#f4e7dc",
    textColor: "#FF9E44",
  },
  Opportunities: {
    borderColor: "#28BE5B",
    backgroundColor: "#d8f1e0",
    textColor: "#28BE5B",
  },
  default: {
    borderColor: "#7D8380",
    backgroundColor: "#F2F3E6",
    textColor: "#7D8380",
  },
  custom: {
    borderColor: "#7D8380",
    backgroundColor: "#E9F1ED",
    textColor: "#7D8380",
  },
};

export default CHIPS_COLORS;
