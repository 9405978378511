import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import { IoCreateOutline } from "react-icons/io5";
import SingleSelectDropdown from "../components/SingleSelectDropdown";
import toast from "react-hot-toast";
import Loader from "../components/statusIndicator/loader";
import CHIPS_COLORS from "../constants/chipsColorConstants";

const sampleListForIfCondition = [
  "Campaign status",
  "Brand safety settings",
  "Sensitivity settings",
  "End date",
  "Frequency cap",
  "Geography",
  "Language",
  "Performance goal",
  "Start date",
  "Budget",
  "Pacing period",
  "Pacing type",
  "Bid strategy",
  "Partner revenue model",
];

const operatorsList = ["=", "<", ">", "<=", ">=", "!="];

const CreateRule: React.FC = () => {
  const [ruleTitle, setRuleTitle] = useState<string>("");
  const [dsp, setDsp] = useState<string | null>(null);
  const [level, setLevel] = useState<string | null>(null);
  const [selectedCriticality, setSelectedCriticality] = useState("Threats");
  const [ifCondition, setIfCondition] = useState<string | null>(null);
  const [operator, setOperator] = useState<string>("=");
  const [value, setValue] = useState<string>("");
  const [logicalOperator, setLogicalOperator] = useState<string | null>("AND");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isAddAnotherCondition, setIsAddAnotherCondition] =
    useState<boolean>(true);
  const [loaderOnCreateRuleBtnClick, setLoaderOnCreateRuleBtnClick] =
    useState<boolean>(false);
  const [conditionsList, setConditionsList] = useState<string[]>([]);

  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  const resetStates = () => {
    setLevel(null);
    setIfCondition(null);
    setOperator("=");
    setValue("");
    setLogicalOperator("AND");
    setErrorMessage("");
  };

  const handleCreateRuleClick = () => {
    if (
      !ruleTitle ||
      !dsp ||
      !selectedCriticality ||
      conditionsList.length === 0
    ) {
      toast.error("Fill all the fields and add at least one condition");
      return;
    }

    setLoaderOnCreateRuleBtnClick(true);
    setTimeout(() => {
      setLoaderOnCreateRuleBtnClick(false);
      toast.success("Rule Created");
      resetStates();
      setConditionsList([]);
    }, 2000);
  };

  const handleAddCondition = () => {
    if (!level || !ifCondition || !operator || !value) {
      toast.error("Please fill all the fields before adding the condition");
      return;
    }

    // Create the condition string
    let conditionString = `For ${level} if ${ifCondition} ${operator} ${value}`;

    // If this is not the first condition, prepend the logical operator
    if (conditionsList.length > 0 && logicalOperator) {
      conditionString = `${logicalOperator} ${conditionString}`;
    }

    // Add the condition to the list
    setConditionsList((prevList) => [...prevList, conditionString]);

    // Reset fields for the next condition
    resetStates();
  };

  const getChipColors = (item: string) => {
    const isSelected = item === selectedCriticality;

    const color = isSelected
      ? CHIPS_COLORS[
          selectedCriticality.toLowerCase() as keyof typeof CHIPS_COLORS
        ]
      : CHIPS_COLORS.default;

    return {
      backgroundColor: isSelected ? color.backgroundColor : "transparent",
      borderColor: color.borderColor,
      color: color.textColor,
    };
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="create rule" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <IoCreateOutline size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Rule Creation
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Create your custom rules
            </p>
          </div>
        </div>

        {/* form component */}
        <div className="mt-6 mb-32">
          <div className="flex flex-col gap-4 mb-5">
            <label htmlFor="rule_title" className="font-bold text-sm">
              Rule Description
            </label>
            <input
              type="text"
              placeholder="Rule Description"
              className="w-[410px] h-10 border-[0.5px] border-gray-300 p-3 text-sm"
              value={ruleTitle}
              onChange={(e) => setRuleTitle(e.target.value)}
            />
          </div>
          <div className="mb-5 w-[410px]">
            <label htmlFor="dsp" className="font-bold text-sm">
              DSP
            </label>
            <SingleSelectDropdown
              items={["DV360"]}
              selectedItem={dsp}
              setSelectedItem={setDsp}
            />
          </div>
          <p className="font-bold text-sm">CONDITION</p>

          {/* Show Existing Conditions */}
          <div>
            {conditionsList.map((condition, index) => (
              <div key={index} className="text-sm text-gray-600 mt-2">
                {condition}
              </div>
            ))}

            {/* Show logical operator dropdown only after the first condition */}
            {conditionsList.length > 0 && (
              <div className="w-24 mt-4">
                <SingleSelectDropdown
                  items={["AND", "OR", "NOT"]}
                  selectedItem={logicalOperator}
                  setSelectedItem={setLogicalOperator}
                />
              </div>
            )}

            {/* Level, If Condition, Operator, and Value Inputs */}
            <div className="flex items-center gap-12 mt-2">
              <label htmlFor="if" className="w-24 text-sm">
                Level
              </label>
              <div className="w-[410px]">
                <SingleSelectDropdown
                  items={[
                    "Insertion Order",
                    "Line item",
                    "Creative",
                    "Ad group",
                    "Campaign",
                  ]}
                  selectedItem={level}
                  setSelectedItem={setLevel}
                />
              </div>
            </div>
            <div className="flex items-center gap-12">
              <label htmlFor="if" className="w-24 text-sm">
                If
              </label>
              <div className="w-[410px]">
                <SingleSelectDropdown
                  items={sampleListForIfCondition}
                  selectedItem={ifCondition}
                  setSelectedItem={setIfCondition}
                />
              </div>
            </div>
            <div className="flex items-center gap-12">
              <label htmlFor="is" className="w-24 text-sm">
                is
              </label>
              <div className="flex gap-2">
                <select
                  value={operator}
                  onChange={(event) => setOperator(event?.target.value)}
                  className="w-[190px] h-10 border-[0.5px] border-gray-300 px-3 mt-3 text-bold text-lg outline-none"
                >
                  {operatorsList.map((operatorOption, index) => (
                    <option key={index} value={operatorOption}>
                      {operatorOption}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  placeholder="Value"
                  className="w-[210px] h-10 border-[0.5px] border-gray-300 p-3 text-sm mt-3 outline-none"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Add Condition Button */}
          <button
            className="text-sm text-[#286043] my-3"
            onClick={handleAddCondition}
          >
            Save & Add another condition
          </button>

          {/* Error Message */}
          <div className="flex gap-12 mt-4">
            <label htmlFor="if" className="w-24 text-sm mt-2">
              then show error message
            </label>
            <div className="w-[410px]">
              <textarea
                className="w-[410px] h-[80px] border-[0.5px] border-gray-300 p-3 text-sm mt-3 overflow-auto"
                value={errorMessage}
                onChange={(e) => setErrorMessage(e.target.value)}
              />
            </div>
          </div>

          {/* Criticality */}
          <p className="font-bold text-sm">Criticality</p>
          <div className="flex gap-2 mt-2">
            {["Threats", "Warnings", "Anomalies"].map((item) => (
              <button
                key={item}
                className="border p-2 text-xs"
                style={getChipColors(item)}
                onClick={() => setSelectedCriticality(item)}
              >
                {item}
              </button>
            ))}
          </div>
        </div>

        <div className="fixed bottom-0 left-[100px] right-0 bg-gray-100 border-t border-gray-300 py-8 flex justify-between items-center px-6 z-20 overflow-hidden">
          <button
            className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1"
            onClick={handleCreateRuleClick}
          >
            {loaderOnCreateRuleBtnClick ? (
              <Loader loaderColor="text-white" />
            ) : (
              <span className="text-sm leading-4 text-left font-[600] text-white">
                Create Rule
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRule;
