import React from "react";
import CriticalityChips from "../CriticalityChips";
import CHIPS_COLORS from "../../constants/chipsColorConstants";
import { TaxonomyTableProps } from "../../interfaces/taxonomy";
import NoActionsFound from "../noActionsFound";

const TaxonomyTable: React.FC<TaxonomyTableProps> = ({ tableData }) => {
  if (!tableData) {
    return <NoActionsFound sectionName="" />;
  }

  const { level_name, fields, data } = tableData;

  const renderChips = (status: string) => {
    switch (status.toLowerCase()) {
      case "true":
        return (
          <CriticalityChips type="Correct" style={CHIPS_COLORS.Opportunities} />
        );
      case "missing":
        return (
          <CriticalityChips type="Missing" style={CHIPS_COLORS.anomalies} />
        );
      case "false":
        return (
          <CriticalityChips type="Incorrect" style={CHIPS_COLORS.threats} />
        );
      default:
        return status;
    }
  };

  return (
    <div>
      {/* Render Table Header */}
      <div
        className="text-gray-900 text-xs font-bold border-[0.5px] p-3 border-gray-300 bg-gray-100 grid mt-6"
        style={{ gridTemplateColumns: `repeat(${fields.length + 2}, 1fr)` }}
      >
        <p className="col-span-2">{level_name + " name"}</p>
        {fields.filter(Boolean).map((field, index) => (
          <p key={index} className="col-span-1">
            {field}
          </p>
        ))}
      </div>

      {/* Render Data Rows */}
      {data.map((item, index) => {
        const issueKeys = Object.keys(item.issues);

        // Default row values
        let rowValues = fields.map(() => "true");

        if (issueKeys.length > 0) {
          if (item.issues.splitting) {
            // If "splitting" error is present, fill with "missing"
            rowValues = fields.map(() => "false");
          } else {
            // Fill specific fields with the correct value (e.g., false)
            rowValues = fields.map((field) => {
              // Check if the field exists in the issues and is an array
              const issue = item.issues[field];
              if (issue && Array.isArray(issue)) {
                return issue[0] === false ? "false" : "true";
              }
              // Handle case when there is no issue for this field or it's undefined
              return issue === undefined ? "true" : "false";
            });
          }
        }

        return (
          <div
            key={index}
            className="text-gray-900 text-xs text-left border-b-[0.5px] p-3 border-gray-300 grid"
            style={{ gridTemplateColumns: `repeat(${fields.length + 2}, 1fr)` }}
          >
            <p className="col-span-2 whitespace-wrap mr-3">{item.name}</p>
            {rowValues.map((value, idx) => (
              <p key={idx} className="col-span-1 whitespace-nowrap">
                {renderChips(value)}
              </p>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default TaxonomyTable;
