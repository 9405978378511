import React, { useState, useEffect } from "react";
import campaign_page_logo from "../assets/campaigns_logo.png";
import { FaCheckCircle } from "react-icons/fa";
import { FaDotCircle } from "react-icons/fa";
import connection_success from "../assets/connection_success.png";

interface ConnectorPageLayoutProps {
  page_logo: string;
  page_title: string;
  page_initial_path: string;
  page_final_path: string;
  connection_test_label: string;
}

interface ConnectionTestCompProps {
  page_layout: ConnectorPageLayoutProps;
}

const ConnectionTestComp: React.FC<ConnectionTestCompProps> = ({
  page_layout,
}) => {
  const [connectionSuccess, setConnectionSuccess] = useState(false);

  const getConnectionSteps = () => {
    switch (page_layout.page_title) {
      case "Vistar":
        return [
          {
            label: "Connecting to Vistar",
            status: "completed",
          },
        ];
      case "DV 360":
        return [
          {
            label: "Connecting to DV 360",
            status: "completed",
          },
          {
            label: "Connecting to DoubleClick Bid Manager API",
            status: "completed",
          },
        ];
      case "The Trade Desk":
        return [
          {
            label: "Connecting to The Trade Desk",
            status: "completed",
          },
        ];
      case "Google Ads":
        return [
          {
            label: "Connecting to Google Ads",
            status: "completed",
          },
        ];
      case "Meta Ads":
        return [
          {
            label: "Connecting to Meta",
            status: "completed",
          },
        ];

      case "Google Sheets":
        return [
          {
            label: "Connecting to Google Sheets",
            status: "completed",
          },
        ];

      case "Double Verify":
        return [
          {
            label: "Connecting to Double Verify",
            status: "completed",
          },
        ];

      default:
        return [];
    }
  };

  const renderConnectionTestSteps = (item: {
    label: string;
    status: string;
  }) => {
    switch (item.status) {
      case "completed":
        return (
          <div className="flex items-center gap-1 w-full py-4 px-6 bg-[#F4FFFB] mt-6">
            <FaCheckCircle color="green" size={16} />
            <span className="ml-2 text-sm font-semibold">{item.label}</span>
          </div>
        );
      case "in progress":
        return (
          <div className="flex items-center gap-1 w-full py-4 px-6 bg-[#B4921A1A] mt-6">
            <FaDotCircle color="#F4CB25" size={16} />
            <span className="ml-2 text-sm font-semibold">{item.label}</span>
          </div>
        );
      case "pending":
        return (
          <div className="flex items-center gap-1 w-full py-4 px-6 bg-[#F1F2F1] mt-6">
            <FaDotCircle color="#D3D4D5" size={16} />
            <span className="ml-2 text-sm font-semibold">{item.label}</span>
          </div>
        );
      default:
        return "";
    }
  };

  const renderConnectionTestTable = () => (
    <div className="border p-12 mt-24 w-[600px]">
      <p className="text-xs font-bold">
        {page_layout.connection_test_label} :{" "}
      </p>
      <div>
        {getConnectionSteps().map((item, index) => (
          <div key={index}>{renderConnectionTestSteps(item)}</div>
        ))}
      </div>
    </div>
  );

  const renderSuccessfulConnection = () => (
    <div className="border p-12 mt-24 w-[600px] flex flex-col items-center bg-[#F4FFFB]">
      <img
        src={connection_success}
        alt="connection_success"
        className="h-24 w-24"
      />
      <p className="text-xl font-bold mt-6 mb-4">Your data is ready to sync!</p>
      <p className="text-sm font-medium text-center">
        Your connector is ready to sync source data to your destination! Syncing
        may take some time depending on how large your data set is.{" "}
      </p>
      {/* <button className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1 mt-3">
        <p className="text-sm leading-4 text-left font-[600]  text-white">
          Start initial sync
        </p>
      </button> */}
    </div>
  );

  useEffect(() => {
    if (
      page_layout.page_title === "Vistar" ||
      "The Trade Desk" ||
      "DV 360" ||
      "Google Ads" ||
      "Meta Ads" ||
      "Google Sheets" ||
      "Double Verify"
    ) {
      const timer = setTimeout(() => {
        setConnectionSuccess(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [page_layout.page_title]);

  return (
    <>
      <div>
        <span className="text-[10px]">
          {page_layout.page_initial_path} {">"} {page_layout.page_final_path}
        </span>
      </div>
      <div className="flex justify-start items-center gap-4 mt-4">
        <img
          src={page_layout.page_logo}
          alt="opportunities logo"
          className="w-11 h-11"
        />
        <div>
          <p className="text-xl font-[700] leading-6 text-left">
            {page_layout.page_title}
          </p>
        </div>
      </div>
      <div className="border-t-[0.5px] flex justify-center items-center mt-3">
        {connectionSuccess
          ? renderSuccessfulConnection()
          : renderConnectionTestTable()}
      </div>
    </>
  );
};

export default ConnectionTestComp;
