import React from "react";
import vistar_logo from "../assets/connectors_logos/vistar.png";
import googleAds_logo from "../assets/connectors_logos/googleads.png";
import dv360_logo from "../assets/connectors_logos/dv360.png";
import fb_logo from "../assets/connectors_logos/fb.png";
import ttd_logo from "../assets/connectors_logos/ttd.png";
import google_sheets_logo from "../assets/connectors_logos/googlesheets.png";
import double_verify_logo from "../assets/connectors_logos/doubleverify.png";
import amazon_logo from "../assets/connectors_logos/Amazon_ads.png";
import microsoft_logo from "../assets/connectors_logos/Microsoft_Ads_Logo.png";
import salesforce_logo from "../assets/connectors_logos/Salesforce_logo.png";
import shopify_logo from "../assets/connectors_logos/shopify_logo.png";
import tik_tok_logo from "../assets/connectors_logos/TikTok_Logo.png";
import xandr_logo from "../assets/connectors_logos/Xandr_logo.png";
import yahoo_logo from "../assets/connectors_logos/Yahoo_Logo.jpeg";
import ad_form_logo from "../assets/connectors_logos/adform.png";
import cm_360_logo from "../assets/connectors_logos/cm360.png";
import google_ads_manager_logo from "../assets/connectors_logos/google_ads_manager.png";
import linkedin_logo from "../assets/connectors_logos/linkedin.png";
import pinterest_ads from "../assets/connectors_logos/pinterest_ads.png";
import search_ads_360_logo from "../assets/connectors_logos/search_ads_360.png";
import snapchat_ads_logo from "../assets/connectors_logos/snapchat.png";
import spotify_ads_logo from "../assets/connectors_logos/spotify.png";
import twitter_ads_logo from "../assets/connectors_logos/twitter.png";
import ahref_logo from "../assets/connectors_logos/ahref.png";
import semrush_logo from "../assets/connectors_logos/SEMrush.png";
import google_analytics_logo from "../assets/connectors_logos/google_analytics.png";
import bigQuery_logo from "../assets/connectors_logos/big_query.png";
import snowFlak_logo from "../assets/connectors_logos/snowflak.png";
import redshift_logo from "../assets/connectors_logos/redshift.png";
import hubspot_logo from "../assets/connectors_logos/hubspot.png";
import { GoClock } from "react-icons/go";
import { useNavigate } from "react-router-dom";

interface ConnectorProps {
  connector_icon: string;
  connector_text: string;
  connector_btn_label: string;
  connector_setUpTime_label: string;
  connector_setupTime_time: string;
  isDisabled: boolean;
}

interface ConnectorItemProps {
  connector: ConnectorProps;
}

const ConnectorsItemComp: React.FC<ConnectorItemProps> = ({ connector }) => {
  const navigate = useNavigate();

  const getConnectorLogo = () => {
    switch (connector.connector_text.toLowerCase()) {
      case "vistar":
        return vistar_logo;
      case "dv 360":
        return dv360_logo;
      case "the trade desk":
        return ttd_logo;
      case "meta ads":
        return fb_logo;
      case "google ads":
        return googleAds_logo;
      case "google sheets":
        return google_sheets_logo;
      case "double verify":
        return double_verify_logo;
      case "amazon ads":
        return amazon_logo;
      case "microsoft ads":
        return microsoft_logo;
      case "salesforce":
        return salesforce_logo;
      case "shopify.com":
        return shopify_logo;
      case "tik tok":
        return tik_tok_logo;
      case "xandr":
        return xandr_logo;
      case "yahoo":
        return yahoo_logo;
      case "adform":
        return ad_form_logo;
      case "cm360":
        return cm_360_logo;
      case "google ads manager":
        return google_ads_manager_logo;
      case "linkedin ads":
        return linkedin_logo;
      case "pinterest ads":
        return pinterest_ads;
      case "search ads 360":
        return search_ads_360_logo;
      case "snapchat ads":
        return snapchat_ads_logo;
      case "spotify ads":
        return spotify_ads_logo;
      case "twitter ads":
        return twitter_ads_logo;
      case "ahrefs":
        return ahref_logo;
      case "semrush":
        return semrush_logo;
      case "google analytics 4":
        return google_analytics_logo;
      case "bigquery":
        return bigQuery_logo;
      case "snowflak":
        return snowFlak_logo;
      case "redshift":
        return redshift_logo;
      case "hubspot":
        return hubspot_logo;
      default:
        break;
    }
  };

  const handleButtonClick = () => {
    if (!connector.isDisabled) {
      navigate("/connectors", {
        state: { connectorText: connector.connector_text },
      });
    }
  };

  return (
    <div className="border w-[184px]">
      <div className="flex flex-col justify-center items-center mt-6 mb-4">
        <img
          src={getConnectorLogo()}
          alt="connector_logo"
          className="h-12 w-12"
        />
        <p className="my-4 text-sm font-semibold">{connector.connector_text}</p>
        <button
          onClick={handleButtonClick}
          disabled={connector.isDisabled}
          className={`border-[0.75px] border-[#286043] text-xs font-semibold py-[6px] px-3 ${
            connector.isDisabled ? "cursor-not-allowed" : ""
          }`}
        >
          {connector.connector_btn_label}
        </button>
      </div>
      <div className="border-t">
        <p className="flex justify-center items-center my-3 gap-1 text-xs font-bold text-gray-500">
          <GoClock />
          {connector.connector_setUpTime_label} :{" "}
          {connector.connector_setupTime_time}
        </p>
      </div>
    </div>
  );
};

export default ConnectorsItemComp;
