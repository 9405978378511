import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import MultiSelectDropdown from "../MultiSelectDropdown";
import SingleSelectDropdown from "../SingleSelectDropdown";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBack, IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../statusIndicator/loader";
import GreenTick from "../statusIndicator/greenTick";
import CrossMark from "../statusIndicator/cross";
import { getIntegerFromString } from "../../utils/getIntegerFromStr";
import ScheduleSettings from "../SchedulerSettings";

interface SchedulingUserInput {
  interval?: string;
  period?: string;
  minute?: number;
  days_of_week?: string[];
  hour?: number;
  day?: number;
  month?: number;
  time_zone?: string;
}

const UserInputForTtd: React.FC = () => {
  const [configName, setConfigName] = useState<string>("");
  const [apiLogin, setApiLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [atomicAdsChecked, setAtomicAdsChecked] = useState<boolean>(true);
  const [warehouseChecked, setWarehouseChecked] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [fetchedAccounts, setFetchedAccounts] = useState<
    { id: string; name: string }[]
  >([]);
  const [accountsLoading, setAccountsLoading] = useState<boolean>(false);

  const [advertisers, setAdvertisers] = useState<string[]>([]);
  const [fetchedAdvertisers, setFetchedAdvertisers] = useState<
    { advertiser_id: string; advertiser_name: string; partner_id: string }[]
  >([]);
  const [advertisersLoading, setAdvertisersLoading] = useState<boolean>(false);
  const [reportTemplateId, setReportTemplateId] = useState<string>("");
  const [reportTemplateName, setReportTemplateName] = useState<string>("");
  const [historicalSyncTimeFrame, setHistoricalSyncTimeFrame] = useState<
    string | null
  >("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [authorizationStatus, setAuthorizationStatus] = useState<
    "success" | "error" | null
  >(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const isAuthorized = authorizationStatus === "success";
  const [isSaveAndTestActive, setIsSaveAndTestActive] =
    useState<boolean>(false);
  const [saveAndTestLoader, setSaveAndTestLoader] = useState<boolean>(false);

  // external_id
  const [externalId, setExternalId] = useState<string>("");

  // scheduler
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);
  const [schedulingUserInput, setSchedulingUserInput] =
    useState<SchedulingUserInput>({});
  const [schedulePreview, setSchedulePreview] = useState<string>("");

  useEffect(() => {
    if (schedulePreview !== "") {
      setOpenSchedule(false);
    }
  }, [schedulePreview]);

  const handleAuthorizeConnection = async () => {
    setLoading(true);
    setAuthorizationStatus(null);
    try {
      const response = await axiosInstance.post(`/connector/ttd_connector/`, {
        login_username: apiLogin,
        login_password: password,
        configuration_name: configName,
      });

      if (response.status === 200) {
        setAuthorizationStatus("success");
        setIsSaveAndTestActive(true);
        setIsButtonDisabled(true);
        setExternalId(response.data.data.external_id);
      } else {
        throw new Error("Unexpected response");
      }
    } catch (error: any) {
      console.error("Error authorizing connection:", error);
      toast.error(
        error.response?.data?.message ||
          "Authorization failed, please try again."
      );
      setAuthorizationStatus("error");
      setIsSaveAndTestActive(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (externalId !== "") {
      getAccounts();
    }
  }, [externalId]);

  const getAccounts = async () => {
    setAccountsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/connector/get-ttd-partners/${externalId}/`
      );

      if (response.status === 200) {
        setFetchedAccounts(response.data.data.partners);
        setAccountsLoading(false);
      } else {
        throw new Error("Unexpected response while fetching accounts");
        setAccountsLoading(false);
      }
    } catch (error: any) {
      console.error("Error fetching accounts:", error);
      setAccountsLoading(false);
      toast.error(
        error.response?.data?.message ||
          "Error fetching accounts, please try again."
      );
    }
  };

  const getAdvertisersList = async (accountIds: string[]) => {
    setAdvertisersLoading(true);
    try {
      const advertisersPromises = accountIds.map(async (accountId) => {
        const response = await axiosInstance.get(
          `/connector/${accountId}/get-ttd-advertisers/${externalId}/`
        );

        if (response.status === 200) {
          return response.data.data.advertisers;
        } else {
          throw new Error("Unexpected response while fetching advertisers");
        }
      });

      const results = await Promise.all(advertisersPromises);
      const allAdvertisers = results.flat();
      setFetchedAdvertisers(allAdvertisers);
    } catch (error: any) {
      console.error("Error fetching advertisers:", error);
      toast.error(
        error.response?.data?.message ||
          "Error fetching advertisers, please try again."
      );
    } finally {
      setAdvertisersLoading(false);
    }
  };

  const handleAccountChange = () => {
    if (accounts.length > 0) {
      const selectedAccountIds = accounts.map((accountName) => {
        const account = fetchedAccounts.find(
          (account) => account.name === accountName
        );
        return account ? account.id : null;
      });

      const validAccountIds = selectedAccountIds.filter(
        (id): id is string => id !== null
      );

      if (validAccountIds.length > 0) {
        getAdvertisersList(validAccountIds);
      } else {
        setFetchedAdvertisers([]);
      }
    } else {
      setFetchedAdvertisers([]);
    }
  };

  const debouncedHandleAccountChange = debounce(handleAccountChange, 300);

  useEffect(() => {
    debouncedHandleAccountChange();
    return () => {
      debouncedHandleAccountChange.cancel();
    };
  }, [accounts]);

  const getAccountsIdList = () => {
    const result_arr = accounts
      .map((itemName) => {
        const found = fetchedAccounts.find((item) => item.name === itemName);
        return found ? found.id : "";
      })
      .filter((id) => id !== "");
    return result_arr;
  };

  const getAdvertisersIdList = () => {
    const result_arr = advertisers
      .map((itemName) => {
        const found = fetchedAdvertisers.find(
          (item) => item.advertiser_name === itemName
        );
        return found ? found.advertiser_id : "";
      })
      .filter((id) => id !== "");
    return result_arr;
  };

  const handleClickSave = async () => {
    if (
      !reportTemplateId ||
      !reportTemplateName ||
      accounts.length === 0 ||
      !historicalSyncTimeFrame ||
      !schedulingUserInput.time_zone
    ) {
      toast.error("Fill all fields");
      return;
    }

    const getAllAdvertisersIds = () => {
      if (fetchedAdvertisers.length === advertisers.length) {
        return [];
      } else return getAdvertisersIdList();
    };

    setSaveAndTestLoader(true);
    const requestData = {
      template_id: reportTemplateId,
      report_template_name: reportTemplateName,
      partner_ids: getAccountsIdList(),
      advertiser_ids: getAllAdvertisersIds(),
      historical_data_range: getIntegerFromString(historicalSyncTimeFrame),
      external_id: externalId,
      schedule_settings: schedulingUserInput,
    };
    try {
      const response = await axiosInstance.post(
        `/connector/check-ttd-config/`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Configuration checked successfully!");
        setSaveAndTestLoader(false);
        navigate("/validate-connector", { state: { page: "ttd" } });
      } else {
        setSaveAndTestLoader(false);
        throw new Error("Unexpected response while checking configuration");
      }
    } catch (error: any) {
      setSaveAndTestLoader(false);
      toast.error(
        error.response?.data?.message ||
          "Error checking configuration, please try again."
      );
    }
  };

  return (
    <div className="mb-[250px]">
      <div onScroll={(e) => e.stopPropagation()}>
        <div className="mt-4 flex flex-col">
          {/* Configuration Name */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Configuration Name</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12 text-sm"
              value={configName}
              onChange={(e) => setConfigName(e.target.value)}
              disabled={isAuthorized}
            />
          </div>

          {/* API Login */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">API login</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12 text-sm"
              value={apiLogin}
              onChange={(e) => setApiLogin(e.target.value)}
              disabled={isAuthorized}
            />
          </div>

          {/* Password */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Password</h3>
            <input
              type="password"
              className="border outline-none p-3 w-96 h-12 text-sm"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isAuthorized}
            />
          </div>

          {/* Checkboxes */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4"> </h3>
            <div className="flex items-center gap-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="atomic-ads"
                  className="border outline-none p-3 w-4 h-4"
                  checked={atomicAdsChecked}
                  onChange={() => setAtomicAdsChecked(!atomicAdsChecked)}
                />
                <label htmlFor="atomic-ads" className="ml-2 text-sm">
                  Atomic Ads
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="warehouse"
                  className="border outline-none p-3 w-4 h-4"
                  checked={warehouseChecked}
                  disabled
                />
                <label
                  htmlFor="warehouse"
                  className="ml-2 text-sm text-gray-500"
                >
                  Warehouse
                </label>
              </div>
            </div>
          </div>

          {/* Authorize Connection Button */}
          <div className="flex items-center gap-2">
            <button
              className={`border-[0.75px] ${
                isButtonDisabled || !configName || !apiLogin || !password
                  ? "border-gray-300 text-gray-300"
                  : "border-[#286043] text-[#286043]"
              } text-sm font-semibold py-[6px] px-3 w-fit mb-6 flex items-center`}
              onClick={handleAuthorizeConnection}
              disabled={
                isButtonDisabled || !configName || !apiLogin || !password
              }
            >
              {loading && (
                <div className="flex items-center text-xs">
                  <Loader />
                  Authorizing...
                </div>
              )}
              {authorizationStatus === "success" && !loading && (
                <>
                  <GreenTick />
                  Authorized
                </>
              )}
              {authorizationStatus === "error" && !loading && (
                <>
                  <CrossMark />
                  Authorization Failed, Retry
                </>
              )}
              {!loading &&
                authorizationStatus !== "success" &&
                authorizationStatus !== "error" && <>Authorize</>}
            </button>
          </div>

          {/* Report Template Name */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Report Template Name</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12 text-sm"
              value={reportTemplateName}
              onChange={(e) => setReportTemplateName(e.target.value)}
              disabled={!isAuthorized}
              placeholder="Report Template Name ..."
            />
          </div>

          {/* Report Template Id */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Report Template Id</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12 text-sm"
              value={reportTemplateId}
              onChange={(e) => setReportTemplateId(e.target.value)}
              disabled={!isAuthorized}
              placeholder="Report Template Id ..."
            />
          </div>

          {/* Accounts */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Accounts</h3>
            {accountsLoading ? (
              <div className="flex items-center text-xs">
                <Loader />
                Loading Accounts...
              </div>
            ) : (
              <MultiSelectDropdown
                items={fetchedAccounts.map((account) => account.name)}
                selectedItems={accounts}
                setSelectedItems={setAccounts}
                disabled={!isAuthorized}
              />
            )}
          </div>

          {/* Advertisers */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Advertisers</h3>
            {advertisersLoading ? (
              <div className="flex items-center text-xs">
                <Loader />
                Loading Advertisers...
              </div>
            ) : (
              <MultiSelectDropdown
                items={fetchedAdvertisers.map(
                  (advertiser) => advertiser.advertiser_name
                )}
                selectedItems={advertisers}
                setSelectedItems={setAdvertisers}
                disabled={!isAuthorized}
              />
            )}
          </div>

          {/* Historical Sync Time Frame */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">
              Historical sync time frame
            </h3>
            <SingleSelectDropdown
              items={["30 Days", "60 Days", "90 Days"]}
              selectedItem={historicalSyncTimeFrame}
              setSelectedItem={setHistoricalSyncTimeFrame}
              disabled={!isAuthorized}
            />
          </div>

          {/* Scheduler Setting */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Schedule Setting</h3>
            <div className="flex gap-4 items-center">
              <button
                className={`text-sm border border-gray-300 p-2 ${
                  !isAuthorized ? "bg-gray-200" : "bg-white"
                }`}
                onClick={() => setOpenSchedule((prev) => !prev)}
                disabled={!isAuthorized}
              >
                +
              </button>
              <p>{schedulePreview}</p>
            </div>
          </div>
        </div>
      </div>

      {/* scheduler popup */}
      {openSchedule && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className="bg-white shadow-lg min-w-[413px] border border-gray-300"
            style={{
              boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
            }}
          >
            <div className="pt-6 px-8 pb-6">
              <div className="flex justify-end">
                <button
                  onClick={() => setOpenSchedule((prevValue) => !prevValue)}
                  className=""
                >
                  <IoClose />
                </button>
              </div>
              <ScheduleSettings
                setSchedulingUserInput={setSchedulingUserInput}
                setSchedulePreview={setSchedulePreview}
              />
            </div>
          </div>
        </div>
      )}
      <div className="fixed bottom-0 left-[100px] right-0 bg-gray-100 border-t border-gray-300 py-8 flex justify-between items-center px-6 z-20 overflow-hidden">
        <Link
          to="/connectors-library"
          className=" flex items-center gap-2"
          onClick={() => setIsSaveAndTestActive(false)}
        >
          <IoChevronBack size={16} />
          <span className="text-base">Back</span>
        </Link>
        <div className="flex gap-2">
          <button
            className={`border-[0.75px] ${
              isSaveAndTestActive
                ? "border-[#286043] bg-[#286043]"
                : "bg-gray-300 border-gray-300"
            } text-white text-sm font-semibold py-[6px] px-3`}
            disabled={!isSaveAndTestActive}
            onClick={handleClickSave}
          >
            {saveAndTestLoader ? (
              <div className="flex items-center text-xs">
                <Loader loaderColor="text-white" />
                Saving & Testing...
              </div>
            ) : (
              "Save & Test"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInputForTtd;
