import React, { lazy, Suspense, useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import { MdLibraryBooks } from "react-icons/md";
import RulesTable from "../components/rulesLibrary/RulesTable";
import rulesTableData from "../json/tables/Rules.json";
import { Rule } from "../interfaces/rules";
import filter_lines from "../assets/filter_lines.png";
import Loader from "../components/statusIndicator/loader";
import { IoClose } from "react-icons/io5";
import { useProfile } from "../context/ProfileContext";
const RuleLibraryFilterPopup = lazy(
  () => import("../components/rulesLibrary/RuleLibraryFilterPopup")
);

const RuleLibrary: React.FC = () => {
  const { isAdmin } = useProfile();
  const [liveRulesTableData, setLiveRulesTableData] = useState<Rule[]>(
    rulesTableData as Rule[]
  );
  const [archivedRulesTableData, setArchivedRulesTableData] = useState<Rule[]>(
    rulesTableData as Rule[]
  );

  const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<string[]>([]);
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  const closeFilterPopup = () => {
    setShowFilterPopup(false);
  };

  const removeFilterOneByOne = (item: string) => {
    const result = filterList.filter((element) => element !== item);
    setFilterList(result);
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="rule library" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <MdLibraryBooks size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Rule Library
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Keep your rules on track
            </p>
          </div>
        </div>
        <div className="border-l-0 border-r-0 border-b-[0.3px] mt-2 py-4 flex justify-end items-center">
          <div className="flex gap-2 items-center">
            <button
              className="border border-[#286043] p-3 flex justify-center items-center gap-1 h-12"
              onClick={() => setShowFilterPopup(true)}
            >
              <p className="text-sm leading-4 text-left font-[600] text-[#286043]">
                Filter
              </p>
              <img src={filter_lines} alt="three_lines" className="w-[15px]" />
            </button>
            <Link
              to="/create-rule"
              className="border-[0.75px] border-[#286043] bg-[#286043] text-white text-sm font-semibold py-[6px] px-3 h-12 flex items-center"
            >
              Create Rule
            </Link>
          </div>
        </div>
        {filterList.length > 0 && (
          <div className="flex justify-between my-3 gap-3 items-center border-b-[0.75px] pb-3">
            <ul className="flex items-center gap-2 flex-wrap">
              {filterList.map((item, index) => (
                <li
                  key={index}
                  className="border-[0.5px] p-1 text-xs font-normal flex items-center gap-1"
                >
                  {item}{" "}
                  <button onClick={() => removeFilterOneByOne(item)}>
                    <IoClose />
                  </button>
                </li>
              ))}
            </ul>
            <button
              className="text-sm font-semibold text-[#286043] border border-[#286043] p-3 h-[42px] max-w-24 min-w-24"
              onClick={() => setFilterList([])}
            >
              Clear All
            </button>
          </div>
        )}
        {/* Live rules table component */}
        <div>
          <div className="flex justify-between items-center">
            <p className="text-gray-900 text-base leading-5 text-left font-bold py-4">
              Live Rules
              {liveRulesTableData.length > 0
                ? `(${liveRulesTableData.length})`
                : ""}
            </p>
          </div>

          <div className="border-[0.75px] border-gray-300 py-6 px-4">
            <RulesTable tableData={liveRulesTableData} />{" "}
          </div>
        </div>

        {/* Archived rules table component */}
        <div>
          <div className="flex justify-between items-center">
            <p className="text-gray-900 text-base leading-5 text-left font-bold pt-6 pb-6">
              Archived Rules
              {liveRulesTableData.length > 0
                ? `(${liveRulesTableData.length})`
                : ""}
            </p>
          </div>

          <div className="border-[0.75px] border-gray-300 py-6 px-4">
            <RulesTable tableData={archivedRulesTableData} />{" "}
          </div>
        </div>
        {showFilterPopup && (
          <Suspense fallback={<Loader />}>
            <RuleLibraryFilterPopup
              isOpen={showFilterPopup}
              onClose={closeFilterPopup}
              setFilterList={setFilterList}
            />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default RuleLibrary;
