import React from "react";
import opportunities_logo from "../../assets/opportunities_logo.png";
import tileBg from "../../assets/tile_bg.png";
import { Link } from "react-router-dom";

interface Button {
  button_text: string;
  path: string;
}

interface Product {
  product_heading: string;
  product_description: string;
  button: Button;
}

interface ProductsTilesProps {
  product: Product;
}

const ProductsTiles: React.FC<ProductsTilesProps> = ({ product }) => {
  return (
    <div
      className="relative w-[410px] border-[0.45px] border-gray-300 p-6"
      style={{
        background: `radial-gradient(at -227.29% -242.04%, #91D8B4 0%, #FFFFFF 85.49%), url(${tileBg})`,
        backgroundPosition: "left, top right, top right",
        backgroundSize: "cover, auto, contain",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
      }}
    >
      {/* Logo Section */}
      <div className="bg-white w-11 h-11 flex items-center justify-center mb-4">
        <img src={opportunities_logo} alt="tile logo" className="h-5 w-5" />
      </div>

      {/* Product Heading */}
      <p className="text-sm font-bold mb-3">{product.product_heading}</p>

      {/* Description */}
      <p className="text-gray-500 mb-4 text-sm">
        {product.product_description}
      </p>

      {/* Button */}
      <div className="flex flex-wrap gap-2">
        <Link
          to={product.button.path}
          className="px-3 py-2 text-sm font-semibold border border-[#286043] text-[#286043] hover:bg-green-50 transition-colors"
        >
          {product.button.button_text}
        </Link>
      </div>
    </div>
  );
};

export default ProductsTiles;
