import React from "react";
import empty_logo from "../assets/empty.png";
import { useNavigate } from "react-router-dom";

interface NoActionsFoundProps {
  sectionName: string;
  errorHeading?: string;
  errorDescription?: string;
  showActionButton?: boolean;
  actionButtonText?: string;
  actionButtonRedirection?: string;
}

const NoActionsFound: React.FC<NoActionsFoundProps> = ({
  sectionName,
  errorHeading,
  errorDescription,
  showActionButton,
  actionButtonText,
  actionButtonRedirection,
}) => {
  const navigate = useNavigate();

  const handleBtnOnClick = () => {
    if (actionButtonText === "Add new connector") {
      navigate("/connectors-library");
    }
  };

  const renderNoDataFound = () => (
    <div className="border-[0.75px] border-gray-300 py-6 px-4 flex flex-col justify-center items-center">
      <img src={empty_logo} alt="no actions found!" className="h-[160px]" />
      <p className="text-gray-500 text-[18px] leading-6 text-center font-[700] py-4">
        {errorHeading || "No actions found!"}
      </p>
      <p className="text-gray-500 text-xs leading-4 text-center font-[400] pb-2 w-1/2">
        {errorDescription ||
          "No data available. Please check back later for updates."}
      </p>
      {showActionButton && (
        <button
          className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1 mt-3"
          onClick={handleBtnOnClick}
        >
          <p className="text-sm leading-4 text-left font-[600]  text-white">
            {actionButtonText}
          </p>
        </button>
      )}
    </div>
  );

  const renderNoDataFoundForArchive = () => (
    <div className="border-[0.75px] border-gray-300 py-6 px-4">
      <p className="text-gray-500 text-xs leading-4 font-[400] pb-2">
        No action found!
      </p>
    </div>
  );

  return (
    <>
      <p className="text-gray-900 text-base leading-5 text-left font-[700] pt-6 pb-6">
        {sectionName}
      </p>
      {sectionName === "Archive"
        ? renderNoDataFoundForArchive()
        : renderNoDataFound()}
    </>
  );
};

export default NoActionsFound;
