import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import SingleSelectDropdown from "../SingleSelectDropdown";

interface userTableProps {
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateUser: React.FC<userTableProps> = ({ handleClose }) => {
  const [userType, setUserType] = useState<string | null>(null);
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white shadow-lg w-[413px] border border-gray-300"
        style={{
          boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
        }}
      >
        <div className="pt-6 px-8 pb-6">
          <button
            className="py-[6px] pr-[6px]"
            onClick={() => handleClose(false)}
          >
            <IoClose />
          </button>
          <div>
            <p className="font-bold text-[#286043] text-2xl text-center mb-6">
              Add new user
            </p>
            <div className="mb-6 flex flex-col">
              <label className="font-bold text-xs text-[#A1A5B7]">NAME</label>
              <input
                type="text"
                className="border border-[#E4E6EF] text-[#A1A5B7] text-sm p-3 w-full outline-none mt-2  h-[38px]"
                placeholder="Enter Name"
              />
            </div>
            <div className="mb-6 flex flex-col">
              <label className="font-bold text-xs text-[#A1A5B7]">EMAIL</label>
              <input
                type="email"
                className="border border-[#E4E6EF] text-[#A1A5B7] text-sm p-3 w-full outline-none mt-2 h-[38px]"
                placeholder="Enter Email"
              />
            </div>
            <div className="mb-6 flex flex-col text-[#A1A5B7]">
              <label className="font-bold text-xs">Role</label>
              <SingleSelectDropdown
                items={["Admin", "Campaign Manager"]}
                selectedItem={userType}
                setSelectedItem={setUserType}
              />
            </div>
            <button className="border-[0.75px] border-[#286043] bg-[#286043] text-white text-sm font-semibold py-[6px] px-3 w-full my-5">
              Create User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
