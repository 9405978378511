import React from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import CriticalityChips from "./CriticalityChips";
import { formatDateTime } from "../utils/DateAndTimeFormat";
import { PiCalendarBlank } from "react-icons/pi";
import Loader from "./statusIndicator/loader";
import { ReportProps } from "../interfaces/myReports";
import { BsDownload } from "react-icons/bs";
import CHIPS_COLORS from "../constants/chipsColorConstants";
import { get_logo_url } from "../utils/getConnectorsLogo";

interface tableDataProps {
  connector_name: string;
  configuration_name: string;
  status: string;
  last_sync: string | null;
  created_at: string | null;
  updated_at: string | null;
  external_id: string;
}

interface SixColumnsTableItemsProps {
  table_data: tableDataProps | ReportProps;
  handleDeleteConnector?: (id: string) => void;
  handleContinuePauseBtnClick?: (
    btnText: string,
    selectedExternal_id: string
  ) => void;
  pauseContinueLoader?: boolean;
  selectedExternalId?: string;
}

const SixColumnsTableItem: React.FC<SixColumnsTableItemsProps> = ({
  table_data,
  handleDeleteConnector,
  handleContinuePauseBtnClick,
  pauseContinueLoader,
  selectedExternalId,
}) => {
  const renderChips = (status: string) => {
    switch (status.toLowerCase()) {
      case "inactive":
        return (
          <CriticalityChips type={status} style={CHIPS_COLORS.anomalies} />
        );
      case "active":
        return (
          <CriticalityChips type={status} style={CHIPS_COLORS.Opportunities} />
        );
      case "failed":
        return <CriticalityChips type={status} style={CHIPS_COLORS.threats} />;

      default:
        break;
    }
  };

  const renderForMyConnectors = () => {
    const Connector_Data = table_data as tableDataProps;
    return (
      <li className=" text-gray-900 text-xs text-left border-b-[0.5px] p-3 border-gray-300 grid grid-cols-[300px_150px_200px_200px_200px_200px]">
        <div className="flex items-center gap-2">
          {Connector_Data?.connector_name && (
            <img
              src={get_logo_url(Connector_Data.connector_name)}
              alt="connector_logo"
              className="h-4 w-4"
            />
          )}
          <p>{Connector_Data.configuration_name}</p>
        </div>
        <p>
          {pauseContinueLoader &&
          selectedExternalId === table_data.external_id ? (
            <Loader />
          ) : (
            renderChips(table_data.status)
          )}
        </p>
        <p className="flex items-center gap-1">
          <PiCalendarBlank size={16} />
          <span>
            {Connector_Data.last_sync == null
              ? "-"
              : formatDateTime(Connector_Data?.last_sync)}
          </span>
        </p>
        <p className="flex items-center gap-1">
          <PiCalendarBlank size={16} />
          {Connector_Data.created_at == null
            ? "-"
            : formatDateTime(Connector_Data?.created_at)}
        </p>
        <p className="flex items-center gap-1">
          <PiCalendarBlank size={16} />
          {Connector_Data.updated_at == null
            ? "-"
            : formatDateTime(Connector_Data.updated_at)}
        </p>
        <div className="flex gap-2 items-center ml-10">
          {pauseContinueLoader &&
          selectedExternalId === table_data.external_id ? (
            <Loader />
          ) : (
            <button
              className="text-[#286043] underline"
              onClick={() => {
                const btnClicked =
                  table_data.status.toLowerCase() === "active"
                    ? "pause"
                    : "resume";
                if (handleContinuePauseBtnClick) {
                  handleContinuePauseBtnClick(
                    btnClicked,
                    table_data.external_id
                  );
                }
              }}
            >
              {table_data.status.toLowerCase() === "active"
                ? "pause"
                : "resume"}
            </button>
          )}
          <button
            onClick={() =>
              handleDeleteConnector &&
              handleDeleteConnector(table_data.external_id)
            }
          >
            <RiDeleteBinLine color="#F01F1F" size={16} />
          </button>
        </div>
      </li>
    );
  };

  const renderForMyReports = () => {
    const Report_Data = table_data as ReportProps;
    return (
      <li className=" text-gray-900 text-xs text-left border-b-[0.5px] p-3 border-gray-300 grid grid-cols-[300px_150px_200px_200px_200px_200px]">
        <p>{Report_Data.report_id}</p>
        <p>{Report_Data.report_name}</p>
        <p className="flex items-center gap-1">{Report_Data.advertiser}</p>
        <p className="flex items-center gap-1">{Report_Data.complete_date}</p>
        <p className="flex items-center gap-1">
          <CriticalityChips
            type="Success"
            style={{
              borderColor: CHIPS_COLORS.Opportunities.borderColor,
              backgroundColor: CHIPS_COLORS.Opportunities.backgroundColor,
              textColor: CHIPS_COLORS.Opportunities.textColor,
            }}
          />
        </p>
        <div className="flex gap-2 items-center ml-10">
          <BsDownload size={20} color="#286043" className="bold" />
        </div>
      </li>
    );
  };

  const renderTable = () => {
    if ("report_id" in table_data) {
      return renderForMyReports();
    } else {
      return renderForMyConnectors();
    }
  };

  return renderTable();
};

export default SixColumnsTableItem;
