import React from "react";
import { PacingAndMarginProps } from "../../interfaces/pacing&margin";

interface PacingAndMarginTableProps {
  tableData: PacingAndMarginProps[];
  fields: string[];
}

const PacingAndMarginTable: React.FC<PacingAndMarginTableProps> = ({
  tableData,
  fields,
}) => {
  const gridColumnClass = `grid-cols-${fields.length + 1}`;

  return (
    <div>
      {/* Render Table Header */}
      <div
        className="text-gray-900 text-xs font-bold border-[0.5px] p-3 border-gray-300 bg-gray-100 grid mt-6"
        style={{ gridTemplateColumns: `repeat(${fields.length + 0}, 1fr)` }}
      >
        {fields.filter(Boolean).map((field, index) => (
          <p key={index} className="col-span-1 whitespace-nowrap">
            {field}
          </p>
        ))}
      </div>

      {/* Render Data Rows */}
      {tableData.map((item, index) => {
        const rowValues = [
          item.line_item,
          item.advertiser,
          item.campaign,
          item.start_date,
          item.end_date,
          item.days_in_campaign,
          item.days_passed,
          item.days_remaining,
          item["%_pacing"],
          item.actual_margin,
        ];

        return (
          <div
            key={index}
            className="text-gray-900 text-xs text-left border-b-[0.5px] p-3 border-gray-300 grid"
            style={{ gridTemplateColumns: `repeat(${fields.length + 0}, 1fr)` }}
          >
            {rowValues.map((value, idx) => (
              <p key={idx} className="col-span-1 whitespace-wrap">
                {value}
              </p>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default PacingAndMarginTable;
