import React, { useEffect, useState } from "react";
import SingleSelectDropdown from "./SingleSelectDropdown";
import toast from "react-hot-toast";
import { standardTimeZones } from "../constants/index";

interface ScheduleSettingsProps {
  setSchedulingUserInput: React.Dispatch<React.SetStateAction<{}>>;
  setSchedulePreview: React.Dispatch<React.SetStateAction<string>>;
}

const ScheduleSettings: React.FC<ScheduleSettingsProps> = ({
  setSchedulePreview,
  setSchedulingUserInput,
}) => {
  const [interval, setInterval] = useState<number>(1);
  const [period, setPeriod] = useState<string>("minutes");
  const [minute, setMinute] = useState<number | "">("");
  const [daysOfWeek, setDaysOfWeek] = useState<boolean[]>(Array(7).fill(false));
  const [day, setDay] = useState<number | "">("");
  const [month, setMonth] = useState<string>("");
  const [hour, setHour] = useState<number | "">("");
  const [timeZone, setTimeZone] = useState<string | null>(null);

  const periods = ["minutes", "hours", "days", "weeks", "months", "years"];

  const maxInterval = (value = period) => {
    switch (value) {
      case "minutes":
        return 59;
      case "hours":
        return 23;
      case "days":
        return 365;
      case "months":
        return 12;
      case "date":
        return 31;
      case "years":
        return 5;
      default:
        return Infinity;
    }
  };

  const setAllValuesToInitial = () => {
    setInterval(1);
    setMinute("");
    setDaysOfWeek([]);
    setMonth("");
    setDay("");
    setHour("");
  };

  useEffect(() => {
    setAllValuesToInitial();
  }, [period]);

  const getMonthsNames = (month: string) => {
    const monthsArr = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const selectedMonth = monthsArr[Number(month) - 1];
    return selectedMonth;
  };

  // Function to validate input and generate preview
  const generatePreview = () => {
    const getPeriodString = () => {
      if (interval === 1) {
        return period.slice(0, -1);
      }
      return period;
    };

    let preview = `Every ${
      interval === 1 ? "" : interval
    } ${getPeriodString()} ${
      timeZone && period === "minutes" ? timeZone?.slice(0, 9) : ""
    }`;

    if (period === "hours") {
      preview += ` at ${String(minute).padStart(2, "0")} minute(s) ${
        timeZone ? timeZone?.slice(0, 9) : ""
      }`;
    } else if (period === "days") {
      preview += ` at ${String(hour).padStart(2, "0")}:${String(
        minute
      ).padStart(2, "0")}h ${timeZone ? timeZone?.slice(0, 9) : ""}`;
    } else if (period === "weeks") {
      const selectedDays = daysOfWeek
        .map((checked, index) =>
          checked
            ? [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ][index]
            : null
        )
        .filter(Boolean);
      preview += ` on ${selectedDays.join(", ")} at ${String(hour).padStart(
        2,
        "0"
      )}:${String(minute).padStart(2, "0")}h ${
        timeZone ? timeZone?.slice(0, 9) : ""
      }`;
    } else if (period === "months" && day) {
      preview += ` on the ${day}th at ${String(hour).padStart(2, "0")}:${String(
        minute
      ).padStart(2, "0")}h ${timeZone ? timeZone?.slice(0, 9) : ""}`;
    } else if (period === "years" && month && day) {
      preview += ` on ${getMonthsNames(month)}, ${day} at ${String(
        hour
      ).padStart(2, "0")}:${String(minute).padStart(2, "0")}h ${
        timeZone ? timeZone?.slice(0, 9) : ""
      }`;
    }

    return preview;
  };

  const previewText = generatePreview();

  const normalizeDaysOfWeek = (input: (boolean | undefined)[]): boolean[] => {
    const output = Array(7).fill(false);
    input.forEach((value, index) => {
      if (value === true) {
        output[index] = true;
      }
    });

    return output;
  };

  const handleSaveBtnClick = () => {
    if (
      period === "minutes" ||
      "hours" ||
      "days" ||
      "weeks" ||
      "months" ||
      "years"
    ) {
      if (timeZone === null) {
        return toast.error("Select Your Time zone");
      }
    }

    if (period === "weeks") {
      if (daysOfWeek.find((element) => element === true) !== true) {
        return toast.error("Please select day(s) of week");
      }
    }

    if (period === "months") {
      if (!day) {
        return toast.error("Please select date");
      }
    }

    if (period === "years") {
      if (!month) {
        return toast.error("Please select day of month");
      }
      if (!day) {
        return toast.error("Please select day of month");
      }
    }

    const schedulerData = {
      interval,
      period,
      minute,
      days_of_week: normalizeDaysOfWeek(daysOfWeek),
      hour,
      day,
      month,
      time_zone: timeZone,
    };
    setSchedulingUserInput(schedulerData);
    setSchedulePreview(previewText);
  };

  return (
    <div className="">
      <h2 className="text-lg font-semibold mb-4">Schedule Settings</h2>

      {/* time zone input */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Time Zone</label>
        <SingleSelectDropdown
          items={standardTimeZones}
          selectedItem={timeZone}
          setSelectedItem={setTimeZone}
        />
      </div>

      {/* Interval Input */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Every</label>
        <input
          type="number"
          className="border p-2 w-20 text-sm"
          value={interval}
          min={1}
          max={maxInterval()}
          onChange={(e) => {
            const value = Math.max(
              1,
              Math.min(maxInterval(), Number(e.target.value))
            );
            setInterval(value);
          }}
        />
      </div>

      {/* Period Selection */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Period</label>
        <select
          className="border p-2 w-full"
          value={period}
          onChange={(e) => {
            setPeriod(e.target.value);
            // Reset interval when changing period
            setInterval(1);
          }}
        >
          {periods.map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </select>
      </div>

      {/* Conditional Inputs */}
      {period === "hours" && (
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            At which minute?
          </label>
          <input
            type="number"
            className="border p-2 w-full text-sm"
            value={minute}
            min={0}
            max={maxInterval("minutes")}
            onChange={(e) => setMinute(Number(e.target.value))}
          />
        </div>
      )}

      {period === "days" && (
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            At which hh:mm?
          </label>
          <div className="flex space-x-2">
            <input
              type="number"
              className="border p-2 w-20 text-sm"
              placeholder="HH"
              min={0}
              max={maxInterval("hours")}
              value={hour}
              onChange={(e) => setHour(Number(e.target.value))}
            />
            <input
              type="number"
              className="border p-2 w-20 text-sm"
              placeholder="MM"
              min={0}
              max={maxInterval("minutes")}
              value={minute}
              onChange={(e) => setMinute(Number(e.target.value))}
            />
          </div>
        </div>
      )}

      {period === "weeks" && (
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            On which days of the week?
          </label>
          <div className="grid grid-cols-4">
            {[
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ].map((dayName, index) => (
              <label key={dayName} className="flex items-center text-sm">
                <input
                  type="checkbox"
                  checked={daysOfWeek[index]}
                  onChange={() => {
                    const updatedDays = [...daysOfWeek];
                    updatedDays[index] = !updatedDays[index];
                    setDaysOfWeek(updatedDays);
                  }}
                  className="mr-1 text-sm"
                />
                {dayName}
              </label>
            ))}
          </div>
          <label className="block text-sm font-medium mb-2 mt-4">
            At which hh:mm?
          </label>
          <div className="flex space-x-2">
            <input
              type="number"
              className="border p-2 w-20 text-sm"
              placeholder="HH"
              min={1}
              max={maxInterval("hours")}
              value={hour}
              onChange={(e) => setHour(Number(e.target.value))}
            />
            <input
              type="number"
              className="border p-2 w-20 text-sm"
              placeholder="MM"
              min={0}
              max={maxInterval("minutes")}
              value={minute}
              onChange={(e) => setMinute(Number(e.target.value))}
            />
          </div>
        </div>
      )}

      {period === "months" && (
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            On which date of the month?
          </label>
          <input
            type="number"
            className="border p-2 w-full text-sm"
            value={day}
            min={1}
            max={maxInterval("date")}
            onChange={(e) => setDay(Number(e.target.value))}
          />
          <label className="block text-sm font-medium mb-2 mt-4">
            At which hh:mm?
          </label>
          <div className="flex space-x-2">
            <input
              type="number"
              className="border p-2 w-20 text-sm"
              min={1}
              max={maxInterval("hours")}
              placeholder="HH"
              value={hour}
              onChange={(e) => setHour(Number(e.target.value))}
            />
            <input
              type="number"
              className="border p-2 w-20 text-sm"
              placeholder="MM"
              min={0}
              max={maxInterval("minutes")}
              value={minute}
              onChange={(e) => setMinute(Number(e.target.value))}
            />
          </div>
        </div>
      )}

      {period === "years" && (
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            On which date of the year?
          </label>
          <div className="flex space-x-2">
            <div className="flex flex-col">
              <label className="block text-sm font-medium mb-2">Month</label>
              <input
                type="number"
                className="border p-2 w-20 text-sm"
                placeholder="Month"
                value={month}
                min={1}
                max={maxInterval("months")}
                onChange={(e) => {
                  setMonth(e.target.value);
                  setDay("");
                }}
              />
            </div>
            <div className="flex flex-col">
              <label className="block text-sm font-medium mb-2">Date</label>
              <input
                type="number"
                className="border p-2 w-20 text-sm"
                placeholder="Day"
                min={1}
                max={
                  Number(month) === 1 ||
                  Number(month) === 3 ||
                  Number(month) === 5 ||
                  Number(month) === 7 ||
                  Number(month) === 8 ||
                  Number(month) === 10 ||
                  Number(month) === 12
                    ? 31
                    : Number(month) === 2
                    ? 28
                    : 30
                }
                value={day}
                onChange={(e) => setDay(Number(e.target.value))}
              />
            </div>
          </div>
          <label className="block text-sm font-medium mb-2 mt-4">
            At which hh:mm?
          </label>
          <div className="flex space-x-2">
            <input
              type="number"
              className="border p-2 w-20 text-sm"
              placeholder="HH"
              min={1}
              max={maxInterval("hours")}
              value={hour}
              onChange={(e) => setHour(Number(e.target.value))}
            />
            <input
              type="number"
              className="border p-2 w-20 text-sm"
              placeholder="MM"
              min={0}
              max={maxInterval("minutes")}
              value={minute}
              onChange={(e) => setMinute(Number(e.target.value))}
            />
          </div>
        </div>
      )}

      {/* Preview Section */}
      <div className="mt-6 p-4 bg-gray-100 rounded">
        <h3 className="text-normal font-semibold">Preview</h3>
        <p className="text-sm">{previewText}</p>
      </div>

      {/* button section */}
      <div className="flex justify-end items-center gap-2 mt-4">
        <button
          className="border border-[#286043] p-2 text-white bg-[#286043] text-sm leading-5 font-[600]"
          onClick={handleSaveBtnClick}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ScheduleSettings;
