import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonTable: React.FC = () => {
  return (
    <div className="overflow-x-auto w-full">
      <h1 className="py-6">
        <Skeleton width={150} />
      </h1>
      <table className="min-w-full text-left text-sm border-[0.75px]">
        <thead>
          <tr>
            <th className="px-4 py-2">
              <Skeleton width={150} />
            </th>
            <th className="px-4 py-2">
              <Skeleton width={80} />
            </th>
            <th className="px-4 py-2">
              <Skeleton width={100} />
            </th>
            <th className="px-4 py-2">
              <Skeleton width={100} />
            </th>
            <th className="px-4 py-2">
              <Skeleton width={150} />
            </th>
            <th className="px-4 py-2">
              <Skeleton width={150} />
            </th>
          </tr>
        </thead>
        <tbody>
          {Array(10)
            .fill(0)
            .map((_, index) => (
              <tr key={index}>
                <td className="px-4 py-2">
                  <Skeleton width={150} />
                </td>
                <td className="px-4 py-2">
                  <Skeleton width={50} height={25} />
                </td>
                <td className="px-4 py-2">
                  <Skeleton width={150} />
                </td>
                <td className="px-4 py-2">
                  <Skeleton width={150} />
                </td>
                <td className="px-4 py-2">
                  <Skeleton width={150} />
                </td>
                <td className="px-4 py-2 flex space-x-2">
                  <Skeleton width={50} height={25} />
                  <Skeleton width={50} height={25} />
                  <Skeleton width={30} height={25} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SkeletonTable;
