import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";

const environment = process.env.REACT_APP_ENV;

const sentryDSN: { [key: string]: string } = {
  beta: "https://2d763d196f9cad60e52e816c6b1794fb@o4507962742996992.ingest.de.sentry.io/4508057939279952",
  prod: "https://b8b36c6d7745a275cc26c3467cbe997e@o4507962742996992.ingest.de.sentry.io/4508057945899088",
};

if (environment === "beta" || environment === "prod") {
  const dsn = sentryDSN[environment];

  if (dsn) {
    Sentry.init({
      dsn: dsn,
      integrations: [],
    });
  } else {
    console.warn("Sentry DSN is not defined for the current environment.");
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
