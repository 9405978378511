export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ?? "https://api.beta.atomicads.ai/api";

export const standardTimeZones = [
  "UTC-12:00 - Baker Island Time (BIT)",
  "UTC-11:00 - Niue Time (NUT), Samoa Standard Time (ST)",
  "UTC-10:00 - Hawaii-Aleutian Standard Time (HAST), Cook Islands Standard Time (CKT)",
  "UTC-09:30 - Marquesas Islands Time (MART)",
  "UTC-09:00 - Alaska Standard Time (AKST)",
  "UTC-08:00 - Pacific Standard Time (PST), Clipperton Island Standard Time",
  "UTC-07:00 - Mountain Standard Time (MST)",
  "UTC-06:00 - Central Standard Time (CST)",
  "UTC-05:00 - Eastern Standard Time (EST)",
  "UTC-04:00 - Atlantic Standard Time (AST), Eastern Caribbean Time (ECT)",
  "UTC-03:30 - Newfoundland Standard Time (NST)",
  "UTC-03:00 - Argentina Time (ART), Brasília Time (BRT)",
  "UTC-02:00 - South Georgia and the South Sandwich Islands Time",
  "UTC-01:00 - Azores Standard Time (AZOT), Cape Verde Time (CVT)",
  "UTC±00:00 - Coordinated Universal Time (UTC), Greenwich Mean Time (GMT)",
  "UTC+01:00 - Central European Time (CET), West Africa Time (WAT)",
  "UTC+02:00 - Eastern European Time (EET), Central Africa Time (CAT)",
  "UTC+03:00 - Moscow Standard Time (MSK), East Africa Time (EAT)",
  "UTC+03:30 - Iran Standard Time (IRST)",
  "UTC+04:00 - Gulf Standard Time (GST), Samara Time (SAMT)",
  "UTC+04:30 - Afghanistan Time (AFT)",
  "UTC+05:00 - Pakistan Standard Time (PKT), Yekaterinburg Time (YEKT)",
  "UTC+05:30 - Indian Standard Time (IST), Sri Lanka Standard Time",
  "UTC+05:45 - Nepal Time (NPT)",
  "UTC+06:00 - Bangladesh Standard Time (BST), Omsk Time (OMST)",
  "UTC+06:30 - Cocos Islands Time (CCT), Myanmar Time (MMT)",
  "UTC+07:00 - Indochina Time (ICT), Novosibirsk Time (NOVT)",
  "UTC+08:00 - China Standard Time (CST), Singapore Standard Time (SGT)",
  "UTC+08:45 - Australian Central Western Standard Time (ACWST)",
  "UTC+09:00 - Japan Standard Time (JST), Korea Standard Time (KST)",
  "UTC+09:30 - Australian Central Standard Time (ACST)",
  "UTC+10:00 - Australian Eastern Standard Time (AEST), Vladivostok Time (VLAT)",
  "UTC+10:30 - Lord Howe Standard Time (LHST)",
  "UTC+11:00 - Magadan Time (MAGT), Solomon Islands Time (SBT)",
  "UTC+12:00 - Fiji Time (FJT), Kamchatka Time (PETT)",
  "UTC+12:45 - Chatham Islands Standard Time (CHAST)",
  "UTC+13:00 - Tonga Time (TOT), Phoenix Islands Time (PHOT)",
  "UTC+14:00 - Line Islands Time (LINT)",
];

export const IS_ADMIN = true;
