import React from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { RulesTableProps } from "../../interfaces/rules";
import { FaUser } from "react-icons/fa";
import CriticalityChips from "../CriticalityChips";
import CHIPS_COLORS from "../../constants/chipsColorConstants";
import { get_logo_url } from "../../utils/getConnectorsLogo";

const RulesTable: React.FC<RulesTableProps> = ({ tableData }) => {
  const renderChips = (status: string) => {
    switch (status.toLowerCase()) {
      case "default":
        return <CriticalityChips type={status} style={CHIPS_COLORS.default} />;
      case "custom":
        return <CriticalityChips type={status} style={CHIPS_COLORS.custom} />;
      case "performance":
        return (
          <CriticalityChips type={status} style={CHIPS_COLORS.Opportunities} />
        );
      case "warning":
        return <CriticalityChips type={status} style={CHIPS_COLORS.warnings} />;
      case "anomaly":
        return (
          <CriticalityChips type={status} style={CHIPS_COLORS.anomalies} />
        );
      case "threat":
        return <CriticalityChips type={status} style={CHIPS_COLORS.threats} />;

      default:
        return status;
    }
  };

  return (
    <div>
      <div className="text-gray-900 text-xs font-bold border-[0.5px] p-3 border-gray-300 bg-gray-100 grid grid-cols-8">
        <p className="col-span-2">RULE DESCRIPTION</p>
        <p className="col-span-1">RULE TYPE</p>
        <p className="col-span-1">CRITICALITY</p>
        <p className="col-span-1">CATEGORY</p>
        <p className="col-span-1">DSP</p>
        <p className="col-span-1">CREATED BY</p>
        <p className="col-span-1">MANAGE RULE</p>
      </div>
      {tableData.map((rule, index) => (
        <ul
          key={index}
          className="text-gray-900 text-xs text-left border-b-[0.5px] p-3 border-gray-300 grid grid-cols-8"
        >
          <p className="col-span-2 whitespace-wrap">{rule.rule_title}</p>
          <p className="col-span-1 whitespace-wrap">
            {renderChips(rule.rule_type)}
          </p>
          <p className="col-span-1 whitespace-wrap">
            {renderChips(rule.criticality)}
          </p>
          <p className="col-span-1 whitespace-wrap">{rule.category}</p>
          <div className="flex items-center gap-2 col-span-1 whitespace-wrap">
            {rule.dsp && (
              <img
                src={get_logo_url(rule.dsp)}
                alt="connector_logo"
                className="h-4 w-4"
              />
            )}
            <p>{rule.dsp}</p>
          </div>
          <p className="col-span-1 whitespace-wrap flex gap-1 items-center">
            <FaUser size={12} />
            {rule.created_by}
          </p>
          <div className="col-span-1 whitespace-wrap flex gap-3">
            <button>
              <FaEdit color="#286043" size={16} />
            </button>
            <button>
              <RiDeleteBinLine color="#F01F1F" size={16} />
            </button>
          </div>
        </ul>
      ))}
    </div>
  );
};

export default RulesTable;
