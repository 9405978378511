import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

interface GoogleOAuthWrapperProps {
  children: React.ReactNode;
}
const YOUR_GOOGLE_CLIENT_ID =
  "570196964066-cpgi28h7c5glu01v5ms9hh6vu9org9a4.apps.googleusercontent.com";
const GoogleOAuthWrapper: React.FC<GoogleOAuthWrapperProps> = ({
  children,
}) => {
  return (
    <GoogleOAuthProvider clientId={YOUR_GOOGLE_CLIENT_ID}>
      {children}
    </GoogleOAuthProvider>
  );
};

export default GoogleOAuthWrapper;
