import React, { useState } from "react";
import UserInputForVistar from "./connectorUserInput/userInputForVistar";
import UserInputForDV360 from "./connectorUserInput/userInputFor360";
import UserInputForFB from "./connectorUserInput/userInputForFB";
import UserInputForGoogleAds from "./connectorUserInput/userInputForGoogleAds";
import UserInputForTtd from "./connectorUserInput/userInputForTtd";
import { Link } from "react-router-dom";
import UserInputForDoubleVerify from "./connectorUserInput/userInputForDoubleVerify";
import UserInputForGoogleSheets from "./connectorUserInput/userInputForGoogleSheets";

interface ConnectorPageLayoutProps {
  page_logo: string;
  page_title: string;
  page_initial_path: string;
  page_final_path: string;
  connection_test_label: string;
}

interface ConnectorUserInputProps {
  page_layout: ConnectorPageLayoutProps;
}

const ConnectorUserInput: React.FC<ConnectorUserInputProps> = ({
  page_layout,
}) => {
  const [isSaveAndTestActive, setIsSaveAndTestActive] =
    useState<Boolean>(false);

  const renderUserInputContainer = () => {
    switch (page_layout.page_title.trim().toLowerCase()) {
      case "dv 360":
        return <UserInputForDV360 />;
      case "vistar":
        return <UserInputForVistar />;
      case "meta ads":
        return <UserInputForFB />;
      case "the trade desk":
        return <UserInputForTtd />;
      case "google ads":
        return <UserInputForGoogleAds />;
      case "double verify":
        return <UserInputForDoubleVerify />;
      case "google sheets":
        return <UserInputForGoogleSheets />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="sticky">
        <span className="text-[10px]">
          <Link
            to="/connectors-library"
            className="text-green-900 cursor-pointer"
          >
            {page_layout.page_initial_path}
          </Link>{" "}
          {">"} {page_layout.page_final_path}
        </span>
        <div className="flex justify-start items-center gap-4 mt-4">
          <img
            src={page_layout.page_logo}
            alt="opportunities logo"
            className="w-11 h-11"
          />
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              {page_layout.page_title}
            </p>
          </div>
        </div>
      </div>
      <div className="border-t-[0.5px] flex items-center mt-3">
        {renderUserInputContainer()}
      </div>
    </>
  );
};

export default ConnectorUserInput;
