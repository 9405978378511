import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import page_layout from "../json/pageJson/myConnectors.json";
import all_connectors from "../json/connectorLibrary.json";
import ConnectorsItemComp from "../components/connectorsItemComp";
import connector_lib_logo from "../assets/connectors_library.webp";
import UserAvatar from "../components/userAvatar/UserAvatar";
import NoActionsFound from "../components/noActionsFound";

const ConnectorLibrary: React.FC = () => {
  const [searchInput, setSearchInput] = useState("");
  const [listOfConnectors, setListOfConnectors] = useState(all_connectors);
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  // Debounce logic
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchInput.trim() === "") {
        setListOfConnectors(all_connectors);
      } else {
        const filteredConnectors = all_connectors.filter((connector) =>
          connector.connector_text
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
        setListOfConnectors(filteredConnectors);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchInput]);

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="connectors library" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        <div className="flex justify-start items-center gap-4 mt-4">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <img
              src={connector_lib_logo}
              alt="connectors library logo"
              className="w-[17.5px] h-[17.5px]"
            />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              {page_layout.connectorLibrary_heading}
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              {page_layout.connectorLibrary_description}
            </p>
          </div>
        </div>

        {/* Connectors Library Heading and search */}
        <div className="mt-12 flex justify-between">
          <p className="text-lg font-[700] text-left">
            {page_layout.connectorLibrary_sub_heading}
          </p>
          <input
            type="search"
            id="search_campaigns"
            className="text-gray-600 h-12 border-gray-500 border-[0.5px] outline-none p-2 text-xs w-64"
            placeholder="Search Connectors"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>

        {/* Connectors Library components Section */}
        <div className="flex justify-start items-center gap-3 mt-8 flex-wrap">
          {listOfConnectors.length > 0 ? (
            listOfConnectors.map((connector, index) => (
              <ConnectorsItemComp connector={connector} key={index} />
            ))
          ) : (
            <NoActionsFound sectionName="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectorLibrary;
