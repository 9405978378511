// src/App.tsx
import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/routes";
import ToastProvider from "./components/ToastProvider";

interface AppInterface {}

const App: React.FC<AppInterface> = () => {
  return (
    <ToastProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ToastProvider>
  );
};

export default App;
