import React, { useEffect, useState } from "react";
import MultiSelectDropdown from "../MultiSelectDropdown";
import SingleSelectDropdown from "../SingleSelectDropdown";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBack, IoClose } from "react-icons/io5";
import { LoginSocialFacebook } from "reactjs-social-login";
import { toast } from "react-hot-toast";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../statusIndicator/loader";
import GreenTick from "../statusIndicator/greenTick";
import CrossMark from "../statusIndicator/cross";
import { getIntegerFromString } from "../../utils/getIntegerFromStr";
import ScheduleSettings from "../SchedulerSettings";

interface SchedulingUserInput {
  interval?: string;
  period?: string;
  minute?: number;
  days_of_week?: string[];
  hour?: number;
  day?: number;
  month?: number;
  time_zone?: string;
}

const breakdownInputs = [
  "ad_format_asset",
  "age",
  "app_id",
  "body_asset",
  "call_to_action_asset",
  "coarse_conversion_value",
  "country",
  "description_asset",
  "device_platform",
  "fidelity_type",
  "gender",
  "hsid",
  "image_asset",
  "impression_device",
  "is_conversion_id_modeled",
  "landing_destination",
  "link_url_asset",
  "postback_sequence_index",
  "product_id",
  "redownload",
  "region",
  "skan_campaign_id",
  "skan_conversion_id",
  "skan_version",
  "title_asset",
  "user_persona_id",
  "user_persona_name",
  "video_asset",
  "dma",
  "frequency_value",
  "hourly_stats_aggregated_by_advertiser_time_zone",
  "hourly_stats_aggregated_by_audience_time_zone",
  "place_page_id",
  "publisher_platform",
  "conversion_destination",
  "signal_source_bucket",
  "marketing_messages_btn_name",
];

const fieldsInputs = [
  "account_currency",
  "account_id",
  "account_name",
  "ad_id",
  "ad_name",
  "adset_id",
  "adset_name",
  "attribution_setting",
  "auction_bid",
  "auction_competitiveness",
  "auction_max_competitor_bid",
  "buying_type",
  "campaign_id",
  "campaign_name",
  "canvas_avg_view_percent",
  "canvas_avg_view_time",
  "clicks",
  "cost_per_unique_click",
  "cpc",
  "cpm",
  "cpp",
  "created_time",
  "ctr",
  "date_start",
  "date_stop",
  "dda_countby_convs",
  "dda_results",
  "frequency",
  "full_view_impressions",
  "full_view_reach",
  "impressions",
  "inline_link_click_ctr",
  "inline_link_clicks",
  "inline_post_engagement",
  "instagram_upcoming_event_reminders_set",
  "instant_experience_clicks_to_open",
  "instant_experience_clicks_to_start",
  "objective",
  "optimization_goal",
  "qualifying_question_qualify_answer_rate",
  "reach",
  "shops_assisted_purchases",
  "social_spend",
  "spend",
  "updated_time",
  "video_play_curve_actions",
  "video_play_retention_0_to_15s_actions",
  "video_play_retention_20_to_60s_actions",
  "video_play_retention_graph_actions",
  "wish_bid",
  "video_p100_watched_actions",
  "video_play_actions",
];

interface userInputProps {}

const UserInputForFB: React.FC<userInputProps> = () => {
  const [configName, setConfigName] = useState<string>("");
  const [atomicAdsChecked, setAtomicAdsChecked] = useState<boolean>(true);
  const [warehouseChecked, setWarehouseChecked] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [breakdowns, setBreakdowns] = useState<string | null>("");
  const [fields, setFields] = useState<string[]>([]);
  const [historicalSyncTimeFrame, setHistoricalSyncTimeFrame] = useState<
    string | null
  >("");
  const [fetchedAccounts, setFetchedAccounts] = useState<
    { id: string; name: string }[]
  >([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState<boolean>(false);

  // external_id
  const [externalId, setExternalId] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [authorizationStatus, setAuthorizationStatus] = useState<
    "success" | "loading" | "error" | null
  >(null);
  const [isFieldsEnabled, setIsFieldsEnabled] = useState<boolean>(false);
  const [isSaveAndTestActive, setIsSaveAndTestActive] =
    useState<Boolean>(false);
  const navigate = useNavigate();

  // scheduler
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);
  const [schedulingUserInput, setSchedulingUserInput] =
    useState<SchedulingUserInput>({});
  const [schedulePreview, setSchedulePreview] = useState<string>("");

  useEffect(() => {
    if (schedulePreview !== "") {
      setOpenSchedule(false);
    }
  }, [schedulePreview]);

  const isAuthorized = authorizationStatus === "success";

  const getAccounts = async () => {
    setIsLoadingAccounts(true);
    try {
      const response = await axiosInstance.get(
        `/connector/get-fb-accounts/${externalId}/`
      );

      if (response.status === 200) {
        setFetchedAccounts(response.data.data.accounts);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  useEffect(() => {
    if (externalId !== "") {
      getAccounts();
    }
  }, [externalId]);

  const handleAuthorizeConnection = async (response: any) => {
    setAuthorizationStatus("loading");
    const facebookAccessToken = response.data.accessToken;
    try {
      const authResponse = await axiosInstance.post(
        `/connector/facebook-connector/`,
        {
          configuration_name: configName,
          access_token: facebookAccessToken,
        }
      );

      if (authResponse.status === 200) {
        setAuthorizationStatus("success");
        setIsSaveAndTestActive(true);
        setIsFieldsEnabled(true);
        setExternalId(authResponse.data.data.external_id);
      } else {
        setAuthorizationStatus("error");
        setIsFieldsEnabled(false);
      }
    } catch (error) {
      console.error("Error authorizing connection:", error);
      setAuthorizationStatus("error");
      setIsFieldsEnabled(false);
    }
  };

  const handleClickSaveAndTest = async () => {
    if (
      accounts.length === 0 ||
      !fields ||
      !historicalSyncTimeFrame ||
      !schedulingUserInput.time_zone
    ) {
      toast.error("Please fill in all fields before saving and testing.");
      return;
    }

    setLoading(true);
    const requestData = {
      configuration_name: configName,
      accounts: accounts,
      breakdowns: breakdowns,
      fields: fields.join(","),
      historical_data_range: getIntegerFromString(historicalSyncTimeFrame),
      external_id: externalId,
      schedule_settings: schedulingUserInput,
    };

    try {
      const response = await axiosInstance.post(
        `/connector/fb-save-and-test/`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Configuration checked successfully!");
        setLoading(false);
        navigate("/validate-connector", { state: { page: "meta ads" } });
      } else {
        setLoading(false);
        toast.error("Error saving and testing, please try again.");
      }
    } catch (error) {
      console.error("Error saving and testing:", error);
      toast.error("Error saving and testing, please try again.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-[250px]">
      <div onScroll={(e) => e.stopPropagation()}>
        <div className="mt-4 flex flex-col">
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Configuration Name</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12"
              value={configName}
              onChange={(e) => setConfigName(e.target.value)}
              disabled={isAuthorized}
            />
          </div>

          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Destination</h3>
            <div className="flex items-center gap-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="atomic-ads"
                  className="border outline-none p-3 w-4 h-4"
                  checked={atomicAdsChecked}
                  onChange={() => setAtomicAdsChecked(!atomicAdsChecked)}
                  disabled={isAuthorized}
                />
                <label htmlFor="atomic-ads" className="ml-2 text-sm">
                  Atomic Ads
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="warehouse"
                  className="border outline-none p-3 w-4 h-4"
                  checked={warehouseChecked}
                  disabled
                />
                <label
                  htmlFor="warehouse"
                  className="ml-2 text-sm text-gray-500"
                >
                  Warehouse
                </label>
              </div>
            </div>
          </div>

          <LoginSocialFacebook
            appId="342620847409792"
            scope="ads_management"
            onReject={(response) => console.log(response)}
            onResolve={(response) => handleAuthorizeConnection(response)}
            className="w-[90px] h-[33px] mb-3"
          >
            <button
              className={`border-[0.75px] ${
                isAuthorized || !configName
                  ? "border-gray-300 text-gray-300"
                  : "border-[#286043] text-[#286043]"
              } text-sm font-semibold py-[6px] px-3 w-fit mb-6 flex items-center`}
              disabled={isAuthorized || !configName}
              onClick={() => setAuthorizationStatus("loading")}
            >
              {authorizationStatus === "loading" && (
                <div className="flex gap-2 items-center">
                  <Loader />
                  Authorizing...
                </div>
              )}
              {authorizationStatus === "success" && (
                <>
                  <GreenTick />
                  <span className="text-[#286043]">Authorized</span>
                </>
              )}
              {authorizationStatus === "error" && (
                <>
                  <CrossMark />
                  Authorization Failed, Retry
                </>
              )}
              {authorizationStatus !== "loading" &&
                authorizationStatus !== "success" &&
                authorizationStatus !== "error" && <>Authorize</>}
            </button>
          </LoginSocialFacebook>

          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Accounts</h3>
            {isLoadingAccounts ? (
              <div className="flex items-center text-xs">
                <Loader />
                Loading Accounts...
              </div>
            ) : (
              <MultiSelectDropdown
                items={fetchedAccounts.map((account) => account.id)}
                selectedItems={accounts}
                setSelectedItems={setAccounts}
                disabled={!isFieldsEnabled}
              />
            )}
          </div>

          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Breakdowns</h3>
            <SingleSelectDropdown
              items={breakdownInputs}
              selectedItem={breakdowns}
              setSelectedItem={setBreakdowns}
              disabled={!isFieldsEnabled}
            />
          </div>

          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Fields</h3>
            <MultiSelectDropdown
              items={fieldsInputs}
              selectedItems={fields}
              setSelectedItems={setFields}
              disabled={!isFieldsEnabled}
            />
          </div>

          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">
              Historical sync time frame
            </h3>
            <SingleSelectDropdown
              items={["30 Days", "60 Days", "90 Days"]}
              selectedItem={historicalSyncTimeFrame}
              setSelectedItem={setHistoricalSyncTimeFrame}
              disabled={!isFieldsEnabled}
            />
          </div>

          {/* Scheduler Setting */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Schedule Setting</h3>
            <div className="flex gap-4 items-center">
              <button
                className={`text-sm border border-gray-300 p-2 ${
                  !isFieldsEnabled ? "bg-gray-200" : "bg-white"
                }`}
                onClick={() => setOpenSchedule((prev) => !prev)}
                disabled={!isFieldsEnabled}
              >
                +
              </button>
              <p>{schedulePreview}</p>
            </div>
          </div>
        </div>
      </div>
      {/* scheduler popup */}
      {openSchedule && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className="bg-white shadow-lg min-w-[413px] border border-gray-300"
            style={{
              boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
            }}
          >
            <div className="pt-6 px-8 pb-6">
              <div className="flex justify-end">
                <button
                  onClick={() => setOpenSchedule((prevValue) => !prevValue)}
                  className=""
                >
                  <IoClose />
                </button>
              </div>
              <ScheduleSettings
                setSchedulingUserInput={setSchedulingUserInput}
                setSchedulePreview={setSchedulePreview}
              />
            </div>
          </div>
        </div>
      )}
      <div className="fixed bottom-0 left-[100px] right-0 bg-gray-100 border-t border-gray-300 py-8 flex justify-between items-center px-6 z-20 overflow-hidden">
        <Link
          to="/connectors-library"
          className=" flex items-center gap-2"
          onClick={() => setIsSaveAndTestActive(false)}
        >
          <IoChevronBack size={16} />
          <span className="text-base">Back</span>
        </Link>
        <div className="flex gap-2">
          <button
            className={`border-[0.75px] ${
              isSaveAndTestActive
                ? "border-[#286043] bg-[#286043]"
                : "bg-gray-300 border-gray-300"
            } text-white text-sm font-semibold py-[6px] px-3`}
            onClick={handleClickSaveAndTest}
            disabled={!isSaveAndTestActive || loading}
          >
            {loading ? (
              <div className="flex gap-2 items-center">
                <Loader loaderColor="text-white" />
                Saving & Testing ...
              </div>
            ) : (
              "Save & Test"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInputForFB;
