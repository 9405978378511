import React from "react";
import SixColumnsTableItem from "./SixColumnsTableItem";
import { ReportProps } from "../interfaces/myReports";

interface TableProps {
  heading1: string;
  heading2: string;
  heading3: string;
  heading4: string;
  heading5: string;
  heading6: string;
}

interface tableDataProps {
  connector_name: string;
  configuration_name: string;
  status: string;
  last_sync: string | null;
  created_at: string | null;
  updated_at: string | null;
  external_id: string;
}

interface SixColumnsTableProps {
  table_layout: TableProps;
  table_data: tableDataProps[] | ReportProps[];
  rendering_for_page?: string;
  handleDeleteConnector?: (id: string) => void;
  handleContinuePauseBtnClick?: (
    btnText: string,
    selectedExternal_id: string
  ) => void;
  pauseContinueLoader?: boolean;
  selectedExternalId?: string;
}

const SixColumnsTable: React.FC<SixColumnsTableProps> = ({
  table_layout,
  table_data,
  handleDeleteConnector,
  handleContinuePauseBtnClick,
  pauseContinueLoader,
  selectedExternalId,
}) => {
  return (
    <div>
      <div className=" text-gray-900 text-xs leading-4 text-left font-bold border-[0.5px] p-3 border-gray-300 bg-gray-100 grid grid-cols-[300px_150px_200px_200px_200px_200px]">
        <p>{table_layout.heading1.toUpperCase()}</p>
        <p>{table_layout.heading2.toUpperCase()}</p>
        <p>{table_layout.heading3.toUpperCase()}</p>
        <p>{table_layout.heading4.toUpperCase()}</p>
        <p>{table_layout.heading5.toUpperCase()}</p>
        <p className="flex gap-2 items-center pr-[60px] ml-10">
          {table_layout.heading6.toUpperCase()}
        </p>
      </div>
      <ul>
        {table_data.map((data, index) => (
          <SixColumnsTableItem
            table_data={data}
            key={index}
            handleDeleteConnector={handleDeleteConnector}
            handleContinuePauseBtnClick={handleContinuePauseBtnClick}
            pauseContinueLoader={pauseContinueLoader}
            selectedExternalId={selectedExternalId}
          />
        ))}
      </ul>
    </div>
  );
};

export default SixColumnsTable;
