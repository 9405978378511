import React from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
  btnType: "Link" | "button";
  logo: string;
  btnLabel: string;
  activePage?: string;
  to?: string;
  onClick?: () => void;
}

const ButtonComp: React.FC<ButtonProps> = ({
  btnType,
  logo,
  btnLabel,
  activePage,
  to,
  onClick,
}) => {
  if (btnType === "Link" && to) {
    return (
      <Link to={to} className="flex flex-col items-center">
        <div
          className={
            activePage === btnLabel.toLocaleLowerCase()
              ? "bg-[#DDE3E1] text-gray-800 p-2 shadow flex justify-center items-center w-[41.5px] h-[41.5px]"
              : ""
          }
        >
          <img src={logo} alt="logo" className="h-[17.5px] w-[17.5px]" />
        </div>
        <p className="text-[10px] mt-3 text-center">{btnLabel}</p>
      </Link>
    );
  }

  if (btnType === "button") {
    return (
      <button onClick={onClick} className="flex flex-col items-center">
        <div
          className={
            activePage === "opportunities"
              ? "bg-[#DDE3E1] text-gray-800 p-2 shadow flex justify-center items-center w-[41.5px] h-[41.5px]"
              : ""
          }
        >
          <img src={logo} alt="logo" className="h-[17.5px] w-[17.5px]" />
        </div>
        <p className="text-[10px] mt-3">{btnLabel}</p>
      </button>
    );
  }
  //just in case
  return <>button</>;
};

export default ButtonComp;
