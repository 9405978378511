import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import vistar_logo from "../assets/connectors_logos/vistar.png";
import dv360_logo from "../assets/connectors_logos/dv360.png";
import ttd_logo from "../assets/connectors_logos/ttd.png";
import fb_logo from "../assets/connectors_logos/fb.png";
import googleAds_logo from "../assets/connectors_logos/googleads.png";
import googleSheets_logo from "../assets/connectors_logos/googlesheets.png";
import doubleVerify_logo from "../assets/connectors_logos/doubleverify.png";
import ConnectorUserInput from "../components/connectorUserInput";
import { useAuth } from "../context/AuthContext";

const connectorPageLayoutVistar = {
  page_logo: vistar_logo,
  page_title: "Vistar",
  page_initial_path: "Connector Library",
  page_final_path: "Vistar",
  connection_test_label: "Connection Test",
};

// TEMP
const connectorPageLayoutForDV360 = {
  page_logo: dv360_logo,
  page_title: "DV 360",
  page_initial_path: "Connector Library",
  page_final_path: "DV 360",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutForTTD = {
  page_logo: ttd_logo,
  page_title: "The Trade Desk",
  page_initial_path: "Connector Library",
  page_final_path: "The Trade Desk",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutForFB = {
  page_logo: fb_logo,
  page_title: "Meta Ads",
  page_initial_path: "Connector Library",
  page_final_path: "Meta Ads",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutGoogleAds = {
  page_logo: googleAds_logo,
  page_title: "Google Ads",
  page_initial_path: "Connector Library",
  page_final_path: "Google Ads",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutDoubleVerify = {
  page_logo: doubleVerify_logo,
  page_title: "Double Verify",
  page_initial_path: "Connector Library",
  page_final_path: "Double Verify",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutGoogleSheets = {
  page_logo: googleSheets_logo,
  page_title: "Google Sheets",
  page_initial_path: "Connector Library",
  page_final_path: "Google Sheets",
  connection_test_label: "Connection Test",
};

const Connector: React.FC = () => {
  const location = useLocation();
  const { accessToken, refreshToken, refreshAccessToken } = useAuth();

  const { connectorText } = (location.state as { connectorText: string }) || {};

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  const renderConnectorUserInput = () => {
    switch (connectorText?.toLowerCase()) {
      case "dv 360":
        return <ConnectorUserInput page_layout={connectorPageLayoutForDV360} />;
      case "the trade desk":
        return <ConnectorUserInput page_layout={connectorPageLayoutForTTD} />;
      case "meta ads":
        return <ConnectorUserInput page_layout={connectorPageLayoutForFB} />;
      case "google ads":
        return (
          <ConnectorUserInput page_layout={connectorPageLayoutGoogleAds} />
        );
      case "vistar":
        return <ConnectorUserInput page_layout={connectorPageLayoutVistar} />;
      case "double verify":
        return (
          <ConnectorUserInput page_layout={connectorPageLayoutDoubleVerify} />
        );
      case "google sheets":
        return (
          <ConnectorUserInput page_layout={connectorPageLayoutGoogleSheets} />
        );
      default:
        return "";
    }
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="connectors library" />
      <div
        className="ml-[100px] bg-white min-h-screen p-6"
        style={{ boxShadow: "0px 10px 15px -3px #0000001A" }}
      >
        {renderConnectorUserInput()}
      </div>
    </div>
  );
};

export default Connector;
