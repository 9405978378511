import React, { useState } from "react";
import Rule_template_data from "../../json/tables/taxonomy/TaxonomyRules.json";
import { get_logo_url } from "../../utils/getConnectorsLogo";
import { DspProps } from "../../interfaces/taxonomyRulesTemplate";

const TaxonomyRuleTemplate: React.FC = () => {
  const [selectDspType, setSelectDspType] = useState<string>("DV360");

  const getAllDsps = () => {
    const allDsps: { dsp_name: string; logo: string }[] =
      Rule_template_data.map((rule: DspProps) => ({
        dsp_name: rule.dsp_name,
        logo: get_logo_url(rule.dsp_name),
      }));
    return allDsps;
  };

  const getActiveDspData = (): DspProps | null => {
    const activeDspRule = Rule_template_data.filter(
      (rule: DspProps) => rule.dsp_name === selectDspType
    );
    return activeDspRule.length > 0 ? activeDspRule[0] : null;
  };

  const activeDsp = getActiveDspData(); // Store the active DSP data

  return (
    <>
      {/* DSP Selectors */}
      <div className="flex gap-2 mt-6">
        {getAllDsps().map((item) => (
          <button
            key={item.dsp_name}
            className={`border p-2 text-sm ${
              selectDspType === item.dsp_name ? "border-[#286043]" : ""
            }`}
            onClick={() => setSelectDspType(item.dsp_name)}
          >
            <span className="flex gap-2">
              <img
                src={item.logo}
                alt={`${item.dsp_name} logo`}
                className="h-5 w-5"
              />
              {item.dsp_name}
            </span>
          </button>
        ))}
      </div>

      {/* Display levels for the selected DSP */}
      {activeDsp && activeDsp.levels && (
        <div className="mt-3">
          {Object.keys(activeDsp.levels).map((levelKey) => (
            <div key={levelKey} className="mt-3 ">
              <h3 className="py-2 text-sm text-gray-600">
                {levelKey} template
              </h3>
              <ul className="flex gap-2">
                {activeDsp.levels?.[levelKey]
                  ?.filter((item) => item !== undefined)
                  .map((attribute, index, array) => (
                    <>
                      <li key={index} className="border px-2 py-1 text-sm">
                        {attribute}
                      </li>
                      {index < array.length - 1 && " - "}
                    </>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default TaxonomyRuleTemplate;
