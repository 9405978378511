import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../constants";
import { useIdentifier } from "../context/IdentifierContext";
import { useAuth } from "../context/AuthContext";
import atomic_adds_logo from "../assets/atomic_adds_logo.webp";
import background_image from "../assets/login_background.webp";

interface RefreshTokenPayload {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  full_name: string;
}

const LoginVerification: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { identifier } = useIdentifier();
  const { setTokensAndName } = useAuth();
  const [email, setEmail] = useState<string | undefined>(undefined);

  const { email: emailFromState, identifier: identifierFromState } =
    (location.state as {
      email?: string;
      identifier?: string;
    }) || {};

  useEffect(() => {
    if (!identifierFromState || identifier !== identifierFromState) {
      navigate("/login");
    } else {
      setEmail(emailFromState);
    }
  }, [identifier, identifierFromState, emailFromState, navigate]);

  const [otp, setOtp] = useState(["", "", "", ""]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const decodeRefreshToken = (token: string): RefreshTokenPayload => {
    try {
      const decoded = jwtDecode<RefreshTokenPayload>(token);
      return decoded;
    } catch (error) {
      console.error("Failed to decode token:", error);
      throw error; // or handle the error as needed
    }
  };

  const handleOtpChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) (nextInput as HTMLInputElement).focus();
      }
    }
  };

  const handleLoginClick = async () => {
    setError(null);
    const otpString = otp.join("");
    if (otpString.length === 4) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${API_BASE_URL}/token/`,
          { email, otp: otpString, identifier },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const { refresh, access } = response.data.data;
          const decodedToken = decodeRefreshToken(refresh);
          const userFullName = decodedToken.full_name;
          setTokensAndName(access, refresh, userFullName);
          navigate("/products");
        } else {
          setError(
            "OTP verification failed. Please check the OTP and try again."
          );
          setOtp(["", "", "", ""]);
        }
      } catch (err: any) {
        if (err.response) {
          setError(
            err.response.data?.message ||
              "Failed to verify OTP. Please try again."
          );
        } else if (err.request) {
          setError("No response received from the server. Please try again.");
        } else {
          setError("An error occurred. Please try again.");
        }
        setOtp(["", "", "", ""]);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please enter a 4-digit OTP.");
    }
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen relative bg-cover bg-center"
      style={{ backgroundImage: `url(${background_image})` }}
    >
      <div className="relative z-10 bg-white pt-12 pb-[65px] px-[70px] shadow-lg w-[480px]">
        <div className="flex justify-center mb-4">
          <img
            src={atomic_adds_logo}
            alt="AtomicAds Icon"
            className="w-16 h-16"
          />
        </div>

        <h1 className="text-center text-2xl font-extrabold text-gray-900 mb-2">
          Check your inbox
        </h1>

        <p className="text-center text-sm text-gray-500 mb-6">
          We sent you an OTP & activation link. Please be sure to check your
          spam folder too.
        </p>

        <div className="flex justify-center space-x-2 mb-6">
          {otp.map((digit, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              value={digit}
              onChange={(e) => handleOtpChange(e, index)}
              maxLength={1}
              className="w-12 h-12 text-center border-2 border-gray-300 focus:outline-none focus:border-green-600 text-2xl"
            />
          ))}
        </div>

        {error && (
          <p className="text-center text-red-500 mb-4 text-xs">{error}</p>
        )}

        <button
          className={`w-full ${
            otp.join("").length === 4 ? "bg-green-700" : "bg-gray-200"
          } text-white py-2 transition duration-200 relative`}
          onClick={handleLoginClick}
          disabled={otp.join("").length !== 4 || loading}
        >
          {loading ? (
            <div className="flex justify-center items-center">
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4h-4z"
                />
              </svg>
            </div>
          ) : (
            "Login"
          )}
        </button>
      </div>
    </div>
  );
};

export default LoginVerification;
