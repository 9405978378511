import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import MultiSelectDropdown from "./MultiSelectDropdown";
import SingleSelectDropdown from "./SingleSelectDropdown";
import axiosInstance from "../utils/axiosInstance";
import toast from "react-hot-toast";
import Loader from "./statusIndicator/loader";
import {
  DOOHValueConstants,
  AudioValueConstants,
  DisplayValueConstants,
  MobileValueConstants,
  SocialValueConstants,
  VideoValueConstants,
  YoutubeValueConstants,
} from "../constants/channelsFields";

interface ReportDialogPopupProp {
  togglePopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const demoMultiDropDownItems = ["item1", "item2", "item3"];

const ReportDialogPopup: React.FC<ReportDialogPopupProp> = ({
  togglePopup,
}) => {
  const [ruleName, setRuleName] = useState<string>("");
  const [advertiser, setAdvertiser] = useState<string | null>("");
  const [bookingId, setBookingId] = useState<string[]>([]);
  const [commencingDay, setCommencingDay] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [floodLightActivity, setFloodLightActivity] = useState<boolean>(false);
  const [enableDiffViews, setEnableDiffViews] = useState<boolean>(false);
  const [enableRawData, setEnableRawData] = useState<boolean>(false);
  const [dooh, setDooh] = useState<string | null>(null);
  const [display, setDisplay] = useState<string | null>("");
  const [audio, setAudio] = useState<string | null>("");
  const [social, setSocial] = useState<string | null>("");
  const [mobile, setMobile] = useState<string | null>("");
  const [video, setVideo] = useState<string | null>("");
  const [youtube, setYoutube] = useState<string | null>("");

  const [allAdvertisers, setAllAdvertisers] = useState<string[]>([]);
  const [allBookingIds, setAllBookingIds] = useState<string[]>([]);
  const [allActiveChannels, setAllActiveChannels] = useState<string[]>([]);

  const [loadingAdvertisers, setLoadingAdvertisers] = useState<boolean>(false);
  const [loadingBookingId, setLoadingBookingId] = useState<boolean>(false);
  const [loadingActiveChannels, setLoadingActiveChannels] =
    useState<boolean>(false);
  const [loadingGenerateReport, setLoadingGenerateReport] =
    useState<boolean>(false);

  const handleCloseReportPopup = () => {
    togglePopup(false);
  };

  const listOfChannelsArr = [
    {
      id: 1,
      heading: "DOOH",
      item: DOOHValueConstants,
      selectedItem: dooh,
      setSelectedItem: setDooh,
    },
    {
      id: 2,
      heading: "Display",
      item: DisplayValueConstants,
      selectedItem: display,
      setSelectedItem: setDisplay,
    },
    {
      id: 3,
      heading: "Audio",
      item: AudioValueConstants,
      selectedItem: audio,
      setSelectedItem: setAudio,
    },
    {
      id: 4,
      heading: "Social",
      item: SocialValueConstants,
      selectedItem: social,
      setSelectedItem: setSocial,
    },
    {
      id: 5,
      heading: "Mobile",
      item: MobileValueConstants,
      selectedItem: mobile,
      setSelectedItem: setMobile,
    },
    {
      id: 6,
      heading: "Video",
      item: VideoValueConstants,
      selectedItem: video,
      setSelectedItem: setVideo,
    },
    {
      id: 7,
      heading: "Youtube",
      item: YoutubeValueConstants,
      selectedItem: youtube,
      setSelectedItem: setYoutube,
    },
  ];

  const setAllValuesToInitial = () => {
    setRuleName("");
    setAdvertiser(null);
    setBookingId([]);
    setCommencingDay("");
    setStartDate("");
    setEndDate("");
    setFloodLightActivity(false);
    setEnableDiffViews(false);
    setEnableRawData(false);
    setDooh(null);
    setDisplay("");
    setAudio("");
    setSocial("");
    setMobile("");
    setVideo("");
    setYoutube("");
    setAllAdvertisers([]);
    setAllBookingIds([]);
    setAllActiveChannels([]);
    setLoadingAdvertisers(false);
    setLoadingBookingId(false);
    setLoadingActiveChannels(false);
    setLoadingGenerateReport(false);
  };

  const filterResponseArray = (response: string[]) => {
    return response.filter((item) => item !== null && typeof item === "string");
  };

  const getAllAdvertisers = async () => {
    setLoadingAdvertisers(true);
    try {
      const response = await axiosInstance.get(
        "/reports/skyrise/get-all-advertisers"
      );
      if (response.status === 200) {
        const validAdvertisers = filterResponseArray(
          response.data.data.advertisers
        );
        setAllAdvertisers(validAdvertisers);
      } else {
        throw new Error("Refresh the page and try again");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error fetching Advertisers, please try again."
      );
    } finally {
      setLoadingAdvertisers(false);
    }
  };

  useEffect(() => {
    getAllAdvertisers();
  }, []);

  const getAllBookingIds = async () => {
    if (advertiser) {
      setLoadingBookingId(true);
      try {
        const response = await axiosInstance.get(
          `/reports/skyrise/get-all-booking-ids/${advertiser}`
        );
        if (response.status === 200) {
          if (
            Array.isArray(response.data.data.booking_ids) &&
            response.data.data.booking_ids[0] !== null &&
            response.data.data.booking_ids[0] !== undefined
          ) {
            const validBookingIds = filterResponseArray(
              response.data.data.booking_ids
            );
            setAllBookingIds(validBookingIds);
          } else {
            setAllBookingIds(["No Booking Id Found"]);
            toast.error(
              "No Booking Id Found, please try again with another Advertiser"
            );
          }
        } else {
          throw new Error("Refresh the page and try again");
        }
      } catch (error: any) {
        toast.error(
          error.response?.data?.message ||
            "Error fetching Booking Ids, please try again."
        );
      } finally {
        setLoadingBookingId(false);
      }
    }
  };

  const getAllActiveChannels = async () => {
    setDooh(null);
    setDisplay("");
    setAudio("");
    setSocial("");
    setMobile("");
    setVideo("");
    setYoutube("");
    if (bookingId.length > 0) {
      setLoadingActiveChannels(true);
      try {
        const response = await axiosInstance.post(
          `/reports/skyrise/get-all-active-channels/${advertiser}`,
          {
            booking_ids: bookingId,
          }
        );
        if (response.status === 200) {
          const validChannels = filterResponseArray(
            response.data.data.channels_list
          );
          setAllActiveChannels(validChannels);
        } else {
          throw new Error("Refresh the page and try again");
        }
      } catch (error: any) {
        toast.error(
          error.response?.data?.message ||
            "Error fetching Advertisers, please try again."
        );
      } finally {
        setLoadingActiveChannels(false);
      }
    }
  };

  useEffect(() => {
    if (advertiser) {
      getAllBookingIds();
    } else {
      setAllBookingIds([]);
    }
  }, [advertiser]);

  useEffect(() => {
    if (bookingId.length > 0) {
      getAllActiveChannels();
    } else {
      setAllActiveChannels([]);
    }
  }, [bookingId]);

  const getRequestDataListForDifferentialViews = (
    channels: string[],
    values: { [key: string]: string }
  ): { name: string; value: string }[] => {
    return channels
      .map((channel) => ({
        name: channel,
        value: values[channel.toLowerCase()],
      }))
      .filter(
        (item) =>
          item.value !== undefined && item.value !== null && item.value !== ""
      );
  };

  const getDifferentChannelsView = () => {
    if (enableDiffViews) {
      const channelValues: { [key: string]: string | null } = {
        display: display,
        video: video,
        youtube: youtube,
        mobile: mobile,
        dooh: dooh,
        audio: audio,
        social: social,
      };

      let requestedValues: { [key: string]: string } = {};
      allActiveChannels.forEach((channel) => {
        const key = channel.toLowerCase();
        if (key in channelValues) {
          const value = channelValues[key];
          if (value) {
            requestedValues[key] = value;
          }
        }
      });

      if (Object.keys(requestedValues).length === 0) {
        console.error("No valid channel values provided.");
        return [];
      }

      return getRequestDataListForDifferentialViews(
        allActiveChannels,
        requestedValues
      );
    } else {
      return [];
    }
  };

  const handleClickGenerateReport = async () => {
    if (
      !ruleName ||
      !advertiser ||
      !commencingDay ||
      !startDate ||
      bookingId.length < 1
    ) {
      toast.error("Please fill all data");
      return;
    }

    const getTodaysDate = () => {
      const date = new Date();

      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    };

    const requestData = {
      advertiserName: advertiser,
      dealLineId: bookingId,
      weekCommencingDate: commencingDay,
      campaignName: ruleName,
      startDate: startDate,
      endDate: endDate || getTodaysDate(),
      needRawData: enableRawData,
      floodLightAct: floodLightActivity,
      differentChannelViews: getDifferentChannelsView(),
    };
    setLoadingGenerateReport(true);
    try {
      const response = await axiosInstance.post(
        "/reports/skyrise/generate-report",
        requestData
      );
      if (response.status === 200) {
        toast.success(
          "Report Generated Successfully, Will be sent to your email shortly"
        );
      } else {
        throw new Error("Refresh the page and try again");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error while generating report, please try again."
      );
    } finally {
      setAllValuesToInitial();
      setLoadingGenerateReport(false);
      handleCloseReportPopup();
      getAllAdvertisers();
    }
  };

  return (
    <div>
      <div className="pt-6 pb-6">
        <p className="font-bold text-xl">
          Unified marketing performance report
        </p>
        <div className="py-6">
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">
              REPORT CAMPAIGN NAME*
            </h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-[42px] text-sm"
              placeholder="Report Name..."
              value={ruleName}
              onChange={(e) => setRuleName(e.target.value)}
              disabled={false}
              required
            />
          </div>
          <div className="mb-6 flex justify-start gap-5">
            <div className="w-96">
              <h3 className="text-sm font-semibold mb-4">ADVERTISERS NAME*</h3>
              {loadingAdvertisers ? (
                <Loader />
              ) : (
                <SingleSelectDropdown
                  items={allAdvertisers}
                  selectedItem={advertiser}
                  setSelectedItem={setAdvertiser}
                  disabled={allAdvertisers.length ? false : true}
                />
              )}
            </div>
            <div className="w-96">
              <h3 className="text-sm font-semibold mb-4">
                WEEK COMMENCING DATE*
              </h3>
              <input
                type="date"
                value={commencingDay}
                className="border h-[42px] px-2 py-1 w-full text-sm"
                onChange={(e) => setCommencingDay(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="mb-6 flex justify-start gap-5">
            <div className="w-96">
              <h3 className="text-sm font-semibold mb-4">START DATE*</h3>
              <input
                type="date"
                value={startDate}
                className="border h-[42px] px-2 py-1 w-full text-sm"
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
            <div className="w-96">
              <h3 className="text-sm font-semibold mb-4">END DATE</h3>
              <input
                type="date"
                value={endDate}
                className="border h-[42px] px-2 py-1 w-full text-sm"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-6 flex justify-start gap-5">
            <div className="w-96">
              <h3 className="text-sm font-semibold mb-4">BOOKING ID*</h3>
              {loadingBookingId || loadingActiveChannels ? (
                <Loader />
              ) : (
                <MultiSelectDropdown
                  items={allBookingIds}
                  selectedItems={bookingId}
                  setSelectedItems={setBookingId}
                  disabled={advertiser === "" ? true : false}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-5 mb-6">
            <label className="inline-flex items-center me-5 cursor-pointer w-80">
              <span className="text-xs font-normal">
                Do you need the floodlight activity/ Pixel data?
              </span>
              <input
                type="checkbox"
                className="sr-only peer"
                checked={floodLightActivity}
                onChange={() =>
                  setFloodLightActivity((prevState) => !prevState)
                }
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-[#286043] dark:peer-focus:ring-[#286043] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#286043] outline-none border-none ml-3"></div>
            </label>
            <label className="inline-flex items-center me-5 cursor-pointer w-96">
              <span className="text-xs font-normal">
                Do you need to enable different views for each channel?
              </span>
              <input
                type="checkbox"
                className="sr-only peer"
                checked={enableDiffViews}
                onChange={() => setEnableDiffViews((prevState) => !prevState)}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-[#286043] dark:peer-focus:ring-[#286043] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#286043] outline-none border-none ml-3"></div>
            </label>
            <label className="inline-flex items-center me-5 cursor-pointer w-[450px]">
              <span className="text-xs font-normal">
                Need Raw Data ? <br />
                (Please avoid selecting raw data tabs to speed up report
                processing.)
              </span>
              <input
                type="checkbox"
                className="sr-only peer"
                checked={enableRawData}
                onChange={() => setEnableRawData((prevState) => !prevState)}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-[#286043] dark:peer-focus:ring-[#286043] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#286043] outline-none border-none ml-3"></div>
            </label>
          </div>
          <h3 className="text-sm font-semibold mb-4">LIST OF CHANNELS</h3>
          {bookingId.length > 0 ? (
            <div>
              {loadingActiveChannels ? (
                <div className="mb-24">
                  <Loader />
                </div>
              ) : enableDiffViews ? (
                <div className="mb-64 flex gap-5 flex-wrap">
                  {listOfChannelsArr
                    .filter((channel) =>
                      allActiveChannels.includes(channel.heading)
                    )
                    .map((item) => (
                      <div className="w-48" key={item.id}>
                        <p className="text-xs font-semibold">{item.heading}</p>
                        <SingleSelectDropdown
                          items={item.item}
                          selectedItem={item.selectedItem}
                          setSelectedItem={item.setSelectedItem}
                        />
                      </div>
                    ))}
                </div>
              ) : (
                <p className="text-sm mb-24">{allActiveChannels.join(", ")}</p>
              )}
            </div>
          ) : (
            <p className="text-xs mb-24">
              Select booking IDs first to check the list of active channels
            </p>
          )}
        </div>
      </div>
      <div className="fixed bottom-0 left-[100px] right-0 bg-gray-100 border-t border-gray-300 py-8 flex justify-between items-center px-6 z-20 overflow-hidden">
        <button
          className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1"
          onClick={() => handleClickGenerateReport()}
          disabled={loadingGenerateReport}
        >
          {loadingGenerateReport ? (
            <Loader loaderColor="white" />
          ) : (
            <span className="text-sm leading-4 text-left font-[600]  text-white">
              Generate Report
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default ReportDialogPopup;
