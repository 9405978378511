import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import NoActionsFound from "../components/noActionsFound";
import { TbSpacingVertical } from "react-icons/tb";
import { PacingAndMarginProps } from "../interfaces/pacing&margin";
import PacingAndMarginTableData from "../json/tables/pacing&margin/pacingAndMargin.json";
import SkeletonTable from "../components/Skeleton";
import PacingAndMarginTable from "../components/pacingandmargin/PacingAndMarginTable";

const PacingMargin: React.FC = () => {
  const [pacingMarginData, setPacingMarginData] = useState<
    PacingAndMarginProps[]
  >([]);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false);
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();

  const columnFields = [
    "Line Item",
    "Advertiser",
    "Campaign",
    "Start Date",
    "End Date",
    "Days in campaign",
    "Days Passed",
    "Days Remaining",
    "% Pacing",
    "Actual Margin",
  ];

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    } else {
      getTableDataForPacingAndMargin();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  const getTableDataForPacingAndMargin = () => {
    setLoadingTableData(true);
    setTimeout(() => {
      setPacingMarginData(PacingAndMarginTableData);
      setLoadingTableData(false);
    }, 200);
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="pacing & margin" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <TbSpacingVertical size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Pacing & Margin
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Check all pacing and margin at one glance
            </p>
          </div>
        </div>
        {/* component */}
        {loadingTableData ? (
          <SkeletonTable />
        ) : pacingMarginData.length > 0 ? (
          <PacingAndMarginTable
            tableData={pacingMarginData}
            fields={columnFields}
          />
        ) : (
          <NoActionsFound sectionName="Pacing & Margin" />
        )}
      </div>
    </div>
  );
};

export default PacingMargin;
