import React, { createContext, useState, useContext, ReactNode } from "react";

interface IdentifierContextProps {
  identifier: string | null;
  setIdentifier: (identifier: string | null) => void;
}

const IdentifierContext = createContext<IdentifierContextProps | undefined>(
  undefined
);

export const useIdentifier = () => {
  const context = useContext(IdentifierContext);
  if (!context) {
    throw new Error("useIdentifier must be used within an IdentifierProvider");
  }
  return context;
};

export const IdentifierProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [identifier, setIdentifier] = useState<string | null>(null);

  return (
    <IdentifierContext.Provider value={{ identifier, setIdentifier }}>
      {children}
    </IdentifierContext.Provider>
  );
};
