import google_ads_logo from "../assets/connectors_logos/googleads.png";
import vistar_ads_logo from "../assets/connectors_logos/vistar.png";
import double_verify_logo from "../assets/connectors_logos/doubleverify.png";
import google_sheets_logo from "../assets/connectors_logos/googlesheets.png";
import fb_ads_logo from "../assets/connectors_logos/fb.png";
import dv360_ads_logo from "../assets/connectors_logos/dv360.png";
import ttd_ads_logo from "../assets/connectors_logos/ttd.png";

export const get_logo_url = (logo: string) => {
  switch (logo.toLowerCase()) {
    case "GOOGLE ADS".toLowerCase():
      return google_ads_logo;
    case "FB ADS".toLowerCase():
      return fb_ads_logo;
    case "META ADS".toLowerCase():
      return fb_ads_logo;
    case "DV360".toLowerCase():
      return dv360_ads_logo;
    case "TTD".toLowerCase():
      return ttd_ads_logo;
    case "The trade desk".toLowerCase():
      return ttd_ads_logo;
    case "VISTAR".toLowerCase():
      return vistar_ads_logo;
    case "GOOGLE SHEETS".toLowerCase():
      return google_sheets_logo;
    case "DOUBLE VERIFY".toLowerCase():
      return double_verify_logo;
    default:
      return "";
  }
};
