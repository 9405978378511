export const DOOHValueConstants = [
  "Creative_Type",
  "Format",
  "Skyrise_Audience",
  "Media_Owner",
  "Venue",
  "Creative",
  "Creative_Variant",
  "Screen",
  "Creative_Size",
  "Line_Item",
  "Campaign",
  "Platform",
];

export const DisplayValueConstants = [
  "Campaign_Type",
  "Format",
  "Creative",
  "Creative_Size",
  "Creative_Variant",
  "Skyrise_Audience",
  "Audience_Strategy",
  "Device",
  "Environment",
  "Floodlight_Activity_Name",
  "Line_Item",
  "Insertion_Order",
  "Campaign",
  "Platform",
];

export const VideoValueConstants = [
  "Campaign_Type",
  "Creative_Duration",
  "Skyrise_Audience ",
  "Audience_Strategy",
  "Creative",
  "Creative_Variant",
  "Device",
  "Environment",
  "Floodlight_Activity_Name",
  "Insertion_Order",
  "Line_Item",
  "Campaign",
  "Platform",
];

export const AudioValueConstants = [
  "Campaign_Type",
  "Publisher",
  "Skyrise_Audience",
  "Audience_Strategy",
  "Creative",
  "Creative_Duration",
  "Creative_Variant",
  "Insertion_Order",
  "Line_Item",
  "Platform",
];

export const YoutubeValueConstants = [
  "Campaign_Type",
  "Format",
  "Skyrise_Audience",
  "Audience_Strategy",
  "Creative",
  "Creative_Duration",
  "Creative_Variant",
  "Device",
  "Environment",
  "Line_Item",
  "Campaign",
  "Insertion_Order",
  "Platform",
];

export const SocialValueConstants = [
  "Type",
  "Campaign_Type",
  "Format",
  "Skyrise_Audience",
  "Audience_Strategy",
  "Creative",
  "Creative_Variant",
  "Campaign_Name",
  "Ad_set_name",
  "Platform",
];

export const MobileValueConstants = [
  "Campaign_Type",
  "Format",
  "Skyrise_Audience",
  "Audience_Strategy",
  "Creative",
  "Creative_Size",
  "Creative_Variant",
  "Environment",
  "Campaign_name",
  "Ad_Set_name",
  "Platform",
];
