import React from "react";
import Sidebar from "../components/Sidebar";
import ConnectionTestComp from "../components/ConnectorTestComp";
import vistar_logo from "../assets/connectors_logos/vistar.png";
import dv360_logo from "../assets/connectors_logos/dv360.png";
import ttd_logo from "../assets/connectors_logos/ttd.png";
import fb_logo from "../assets/connectors_logos/fb.png";
import googleAds_logo from "../assets/connectors_logos/googleads.png";
import googleSheets_logo from "../assets/connectors_logos/googlesheets.png";
import doubleVerify_logo from "../assets/connectors_logos/doubleverify.png";
import { useLocation } from "react-router-dom";

const connectorPageLayoutVistar = {
  page_logo: vistar_logo,
  page_title: "Vistar",
  page_initial_path: "Connector Library",
  page_final_path: "Vistar",
  connection_test_label: "Connection Test",
};

// TEMP
const connectorPageLayoutForDV360 = {
  page_logo: dv360_logo,
  page_title: "DV 360",
  page_initial_path: "Connector Library",
  page_final_path: "DV 360",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutForTTD = {
  page_logo: ttd_logo,
  page_title: "The Trade Desk",
  page_initial_path: "Connector Library",
  page_final_path: "The Trade Desk",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutForFB = {
  page_logo: fb_logo,
  page_title: "Meta Ads",
  page_initial_path: "Connector Library",
  page_final_path: "Meta Ads",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutGoogleAds = {
  page_logo: googleAds_logo,
  page_title: "Google Ads",
  page_initial_path: "Connector Library",
  page_final_path: "Google Ads",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutForGoogleSheets = {
  page_logo: googleSheets_logo,
  page_title: "Google Sheets",
  page_initial_path: "Connector Library",
  page_final_path: "Google Sheets",
  connection_test_label: "Connection Test",
};

const connectorPageLayoutForDoubleVerify = {
  page_logo: doubleVerify_logo,
  page_title: "Double Verify",
  page_initial_path: "Connector Library",
  page_final_path: "Double Verify",
  connection_test_label: "Connection Test",
};

const ValidateConnector: React.FC = () => {
  const location = useLocation();
  const { page } = location.state;

  const getPageLayout = () => {
    switch (page) {
      case "vistar":
        return connectorPageLayoutVistar;
      case "dv360":
        return connectorPageLayoutForDV360;
      case "ttd":
        return connectorPageLayoutForTTD;
      case "googleAds":
        return connectorPageLayoutGoogleAds;
      case "meta ads":
        return connectorPageLayoutForFB;
      case "google sheets":
        return connectorPageLayoutForGoogleSheets;
      case "double verify":
        return connectorPageLayoutForDoubleVerify;
      default:
        return {
          page_logo: "",
          page_title: "",
          page_initial_path: "",
          page_final_path: "",
          connection_test_label: "",
        };
    }
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="connectors library" />
      <div
        className="ml-[100px] bg-white min-h-screen p-6"
        style={{ boxShadow: "0px 10px 15px -3px #0000001A" }}
      >
        <ConnectionTestComp page_layout={getPageLayout()} />
      </div>
    </div>
  );
};

export default ValidateConnector;
