import React, { useEffect, useState } from "react";
import { useGoogleLogin, CodeResponse } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBack, IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../statusIndicator/loader";
import GreenTick from "../statusIndicator/greenTick";
import ScheduleSettings from "../SchedulerSettings";
import Google_logo from "../../assets/google_logo.svg";

interface SchedulingUserInput {
  interval?: string;
  period?: string;
  minute?: number;
  days_of_week?: string[];
  hour?: number;
  day?: number;
  month?: number;
  time_zone?: string;
}

interface userInputProps {}

const UserInputForGoogleSheets: React.FC<userInputProps> = () => {
  const [configName, setConfigName] = useState<string>("");
  const [atomicAdsChecked, setAtomicAdsChecked] = useState<boolean>(true);
  const [warehouseChecked, setWarehouseChecked] = useState<boolean>(false);

  const [spreedSheetId, setSpreedSheetId] = useState<string>("");
  const [spreedSheetName, setSpreedSheetName] = useState<string>("");

  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const [isSaveAndTestActive, setIsSaveAndTestActive] =
    useState<boolean>(false);
  const [saveAndTestLoader, setSaveAndTestLoader] = useState<boolean>(false);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [externalId, setExternalId] = useState<string>("");
  const [scope, setScope] = useState<string>("");

  const navigate = useNavigate();

  // scheduler
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);
  const [schedulingUserInput, setSchedulingUserInput] =
    useState<SchedulingUserInput>({});
  const [schedulePreview, setSchedulePreview] = useState<string>("");

  useEffect(() => {
    if (schedulePreview !== "") {
      setOpenSchedule(false);
    }
  }, [schedulePreview]);

  const getGoogleSheetsAuth = async (code: string) => {
    setAuthLoading(true);
    try {
      const response = await axiosInstance.post(
        `/connector/google-sheets/connect`,
        {
          code: code,
          configuration_name: configName,
        }
      );

      if (response.status === 200) {
        setExternalId(response.data.data.external_id);
        setIsAuthorized(true);
        setAuthError(null);
        setIsSaveAndTestActive(true);
        setAuthLoading(false);
      } else {
        setAuthLoading(false);
        toast.error("Auth Error");
        setIsAuthorized(false);
        setAuthError("Authorization failed. Please try again.");
        setIsSaveAndTestActive(false);
      }
    } catch (error) {
      console.error(error);
      setAuthLoading(false);
      toast.error("Authentication Error, Please Retry");
      setIsAuthorized(false);
      setAuthError("Authorization failed. Please try again.");
      setIsSaveAndTestActive(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (
      tokenResponse: Omit<
        CodeResponse,
        "error" | "error_description" | "error_uri"
      >
    ) => {
      getGoogleSheetsAuth(tokenResponse.code);
    },
    onError: (
      error: Pick<CodeResponse, "error" | "error_description" | "error_uri">
    ) => {
      console.error("Google login failed", error);
      setIsAuthorized(false);
      setAuthError("Authorization failed. Please try again.");
      setIsSaveAndTestActive(false);
    },
    flow: "auth-code",
    scope: scope,
    prompt: "consent",
    access_type: "offline",
  } as any);

  // with component load scope will be fetched
  useEffect(() => {
    getScope();
  }, []);

  const getScope = async () => {
    setAuthLoading(true);
    try {
      const response = await axiosInstance.get(
        `/connector/google-sheets/connect`
      );

      if (response.status === 200) {
        setScope(response.data.data.scopes.join(" "));
        setAuthLoading(false);
      }
    } catch (error) {
      setAuthLoading(false);
    }
  };

  const handleAuthorizeClick = () => {
    setAuthLoading(true);
    login();
  };

  const handleSaveAndTest = async () => {
    if (
      spreedSheetId === "" ||
      spreedSheetName === "" ||
      !schedulingUserInput.time_zone
    ) {
      toast.error("Fill all fields");
      return;
    }

    const requestData = {
      spreadsheet_id: spreedSheetId,
      spreadsheet_name: spreedSheetName,
      external_id: externalId,
      schedule_settings: schedulingUserInput,
    };

    setSaveAndTestLoader(true);
    try {
      const response = await axiosInstance.post(
        `/connector/google-sheets/configuration`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Configuration checked successfully!");
        setSaveAndTestLoader(false);
        navigate("/validate-connector", { state: { page: "google sheets" } });
      } else {
        setSaveAndTestLoader(false);
        throw new Error("Unexpected response while checking configuration");
      }
    } catch (error: any) {
      setSaveAndTestLoader(false);
      console.error("Error checking configuration:", error);
      toast.error(
        error.response?.data?.message ||
          "Error checking configuration, please try again."
      );
    }
    setTimeout(() => {
      setSaveAndTestLoader(false);
    }, 2000);
  };

  return (
    <div className="mb-[250px]">
      <div onScroll={(e) => e.stopPropagation()}>
        <div className="mt-4 flex flex-col">
          {/* Configuration Name */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Configuration Name</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-[42px] text-sm"
              placeholder="Configuration Name..."
              value={configName}
              onChange={(e) => setConfigName(e.target.value)}
              disabled={isAuthorized} // Disable if authorized
            />
          </div>

          {/* Destination */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Destination</h3>
            <div className="flex items-center gap-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="atomic-ads"
                  className="border outline-none p-3 w-4 h-4"
                  checked={atomicAdsChecked}
                  onChange={() => setAtomicAdsChecked(!atomicAdsChecked)}
                />
                <label htmlFor="atomic-ads" className="ml-2 text-sm">
                  Atomic Ads
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="warehouse"
                  className="border outline-none p-3 w-4 h-4"
                  checked={warehouseChecked}
                  disabled
                />
                <label
                  htmlFor="warehouse"
                  className="ml-2 text-sm text-gray-500"
                >
                  Warehouse
                </label>
              </div>
            </div>
          </div>

          {/* Authorize Connection Button */}
          <div className="mb-6">
            <button
              className={`border-[0.75px] ${
                configName
                  ? "border-[#286043] text-[#286043]"
                  : "border-gray-300 text-gray-300"
              } text-sm font-semibold py-[6px] px-3 w-fit flex items-center`}
              onClick={handleAuthorizeClick}
              disabled={!configName || isAuthorized || authLoading} // Disable if loading
            >
              {authLoading ? (
                <div className="flex gap-2 items-center">
                  <Loader />
                  Authorizing...
                </div>
              ) : isAuthorized ? (
                <>
                  <GreenTick />
                  Authorized
                </>
              ) : (
                <>
                  <img
                    src={Google_logo}
                    alt="google"
                    className="h-6 w-auto mr-1"
                  />
                  Authorize
                </>
              )}
            </button>

            {/* Error Message */}
            {authError && (
              <p className="text-xs text-red-500 mt-2">{authError}</p>
            )}
          </div>

          {/* SpreedSheet ID */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">SpreedSheet ID</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-[42px] text-sm"
              placeholder="SpreedSheet ID..."
              value={spreedSheetId}
              onChange={(e) => setSpreedSheetId(e.target.value)}
              disabled={!isAuthorized}
            />
          </div>

          {/* Sheet Name */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Sheet Name</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-[42px] text-sm"
              placeholder="SpreedSheet Name..."
              value={spreedSheetName}
              onChange={(e) => setSpreedSheetName(e.target.value)}
              disabled={!isAuthorized}
            />
          </div>

          {/* Scheduler Setting */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Schedule Setting</h3>
            <div className="flex gap-4 items-center">
              <button
                className={`text-sm border border-gray-300 p-2 ${
                  !isAuthorized ? "bg-gray-200" : "bg-white"
                }`}
                onClick={() => setOpenSchedule((prev) => !prev)}
                disabled={!isAuthorized}
              >
                +
              </button>
              <p>{schedulePreview}</p>
            </div>
          </div>
        </div>
      </div>

      {/* scheduler popup */}
      {openSchedule && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className="bg-white shadow-lg min-w-[413px] border border-gray-300"
            style={{
              boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
            }}
          >
            <div className="pt-6 px-8 pb-6">
              <div className="flex justify-end">
                <button
                  onClick={() => setOpenSchedule((prevValue) => !prevValue)}
                  className=""
                >
                  <IoClose />
                </button>
              </div>
              <ScheduleSettings
                setSchedulingUserInput={setSchedulingUserInput}
                setSchedulePreview={setSchedulePreview}
              />
            </div>
          </div>
        </div>
      )}
      {/* Save and test section */}
      <div className="fixed bottom-0 left-[100px] right-0 bg-gray-100 border-t border-gray-300 py-8 flex justify-between items-center px-6 z-20 overflow-hidden">
        <Link
          to="/connectors-library"
          className=" flex items-center gap-2"
          onClick={() => setIsSaveAndTestActive(false)}
        >
          <IoChevronBack size={16} />
          <span className="text-base">Back</span>
        </Link>
        <div className="flex gap-2">
          <button
            className={`border-[0.75px] ${
              isSaveAndTestActive
                ? "border-[#286043] bg-[#286043]"
                : "bg-gray-300 border-gray-300"
            } text-white text-sm font-semibold py-[6px] px-3`}
            disabled={!isSaveAndTestActive}
            onClick={handleSaveAndTest}
          >
            {saveAndTestLoader ? (
              <div className="flex gap-2 items-center">
                <Loader loaderColor="text-white" />
                Saving & Testing...
              </div>
            ) : (
              "Save & Test"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInputForGoogleSheets;
