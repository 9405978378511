import React from "react";
import { UserTableProps } from "../../interfaces/usersPage";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import connectors_logo from "../../assets/my_connector.png";
import qa_logo from "../../assets/campaigns_logo.png";
import reports_logo from "../../assets/reports.png";

const UserTable: React.FC<UserTableProps> = ({ tableData }) => {
  return (
    <div>
      <div className="text-gray-900 text-xs font-bold border-[0.5px] p-3 border-gray-300 bg-gray-100 grid grid-cols-8">
        <p className="col-span-2">NAME</p>
        <p className="col-span-2">EMAIL</p>
        <p className="col-span-1">ROLE</p>
        <p className="col-span-2">ACCESS</p>
        <p className="col-span-1">ACTION</p>
      </div>
      {tableData.map((user, index) => (
        <ul
          key={index}
          className="text-gray-900 text-xs text-left border-b-[0.5px] p-3 border-gray-300 grid grid-cols-8"
        >
          <p className="col-span-2 whitespace-wrap">{user.user_name}</p>
          <p className="col-span-2 whitespace-wrap">{user.user_email}</p>
          <p className="col-span-1 whitespace-wrap">{user.user_role}</p>
          <div className="col-span-2 whitespace-wrap flex items-center gap-2">
            {user.access.connectors && (
              <div className="border-[0.45px] border-gray-500 bg-gray-100 rounded-full p-1">
                <img
                  src={connectors_logo}
                  alt="logo"
                  className="h-[13.5px] w-[13.5px]"
                />
              </div>
            )}
            {user.access.reports && (
              <div className="border-[0.45px] border-gray-500 bg-gray-100 rounded-full p-1">
                <img
                  src={reports_logo}
                  alt="logo"
                  className="h-[13.5px] w-[13.5px]"
                />
              </div>
            )}
            {user.access.qa && (
              <div className="border-[0.45px] border-gray-500 bg-gray-100 rounded-full p-1">
                <img
                  src={qa_logo}
                  alt="logo"
                  className="h-[13.5px] w-[13.5px]"
                />
              </div>
            )}
          </div>
          <p className="col-span-1 whitespace-wrap flex gap-3">
            <button>
              <FaEdit color="#286043" size={16} />
            </button>
            <button>
              <RiDeleteBinLine color="#F01F1F" size={16} />
            </button>
          </p>
        </ul>
      ))}
    </div>
  );
};

export default UserTable;
