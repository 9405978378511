import React from "react";
import { Toaster } from "react-hot-toast";
interface ToastProviderProps {
  children: React.ReactNode;
}

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            background: "#EAFFF6",
            color: "#171D1A",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 500,
          },
        }}
      />
      {children}
    </>
  );
};

export default ToastProvider;
