import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";

interface SingleSelectDropdownProps {
  label?: string | null;
  items: string[];
  selectedItem: string | null;
  setSelectedItem: React.Dispatch<React.SetStateAction<string | null>>;
  disabled?: boolean;
}

const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({
  label,
  items,
  selectedItem,
  setSelectedItem,
  disabled = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Handle item selection
  const handleSelect = (item: string) => {
    if (!disabled) {
      setSelectedItem(item);
      setDropdownOpen(false);
    }
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    if (!disabled) {
      setDropdownOpen((prev) => !prev);
    }
  };

  // Filter items based on search term
  const filteredItems = items.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle clicks outside of the dropdown
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  // Close dropdown on pressing ESC or select on ENTER
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      setDropdownOpen(false); // Close dropdown on ESC
    }
    if (event.key === "Enter" && filteredItems.length > 0) {
      handleSelect(filteredItems[0]); // Auto-select first match on ENTER
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="mt-2" ref={dropdownRef}>
      <label
        className={`text-xs font-medium ${disabled ? "text-gray-500" : ""}`}
      >
        {label}
      </label>
      <div className="relative mt-1">
        <div
          className={`border p-2 cursor-pointer h-10 ${
            disabled ? "bg-gray-200 cursor-not-allowed" : "bg-white"
          }`}
          onClick={toggleDropdown}
        >
          {selectedItem ? (
            <div className="flex items-center justify-between">
              <span className={`text-sm ${disabled ? "text-gray-500" : ""}`}>
                {selectedItem}
              </span>
              <button
                className="ml-1 text-gray-500 hover:text-gray-700"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!disabled) {
                    setSelectedItem(null);
                  }
                }}
              >
                <IoClose size={12} />
              </button>
            </div>
          ) : (
            <span
              className={`text-sm ${
                disabled ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Select {label?.toLowerCase()}...
            </span>
          )}
        </div>

        {dropdownOpen && (
          <div
            className={`absolute z-10 w-full bg-white border mt-1 shadow-lg max-h-48 overflow-auto ${
              disabled ? "pointer-events-none" : ""
            }`}
          >
            <input
              type="text"
              placeholder="Search..."
              className={`p-2 border-b w-full text-sm focus:outline-none ${
                disabled ? "bg-gray-200 cursor-not-allowed" : ""
              }`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={disabled}
              onKeyDown={handleKeyDown}
            />
            <div>
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <div
                    key={item}
                    className={`flex items-center p-2 hover:bg-gray-100 cursor-pointer ${
                      item === selectedItem ? "bg-[#15762A1A] text-black" : ""
                    } ${disabled ? "cursor-not-allowed" : ""}`}
                    onClick={() => handleSelect(item)}
                  >
                    <span className="text-sm">{item}</span>
                  </div>
                ))
              ) : (
                <div className="p-2 text-sm text-gray-400">
                  No results found
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleSelectDropdown;
