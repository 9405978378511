export const formatDateTime = (
  inputDateTime: string | null | undefined
): string => {
  if (inputDateTime == null) {
    return "-";
  }

  const date = new Date(inputDateTime);
  if (isNaN(date.getTime())) {
    return "-";
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleString("en-US", options);
  const [datePart, yearPart, timePart] = formattedDate
    .split(", ")
    .map((part) => part.trim());
  const [month, day] = datePart.split(" ");

  const formattedYear = yearPart ? yearPart.slice(-2) : "";
  return `${day} ${month} ’${formattedYear} | ${timePart.trim()}`;
};
