import React from "react";
import { IconType } from "react-icons";

interface CustomToastProps {
  backgroundColor: string;
  toastIcon: IconType;
  toastText: string;
  iconColor: string;
  t: any;
}

const CustomToast: React.FC<CustomToastProps> = ({
  backgroundColor,
  toastIcon: ToastIcon,
  toastText,
  iconColor,
  t,
}) => {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } bg-white shadow-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      style={{ backgroundColor }}
    >
      <div className="flex px-6 py-4">
        <div className="flex-shrink-0">
          <ToastIcon className="h-6 w-6" color={iconColor} />
        </div>
        <div className="ml-3 flex-1">
          <p className="text-base font-medium text-gray-900">{toastText}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomToast;
