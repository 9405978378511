import React from "react";
import { IoClose } from "react-icons/io5";
import { IconType } from "react-icons";

interface DialogComponentProps {
  closeDialog: () => void;
  dialog_logo: IconType;
  dialog_heading: string;
  dialogSuccessAction: () => void;
  successBtnText: string;
  failureBtnText: string;
  showNote: boolean;
  action_type: string;
}

const DialogComponent: React.FC<DialogComponentProps> = ({
  closeDialog,
  dialog_logo: DialogLogo,
  dialog_heading,
  dialogSuccessAction,
  successBtnText,
  failureBtnText,
  showNote,
  action_type,
}) => {
  const handleTrueAction = () => {
    dialogSuccessAction();
  };

  const handleFalseAction = () => {
    closeDialog();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white shadow-lg w-[413px] border border-gray-300"
        style={{
          boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
        }}
      >
        <div className={`pt-6 px-8 ${!showNote ? "pb-6" : ""}`}>
          <div className="flex gap-2">
            <div className="flex gap-2 mt-6">
              <div
                style={{
                  backgroundColor:
                    action_type === "delete" ? "#F01F1F1A" : "#F5F3FF",
                }}
                className="h-8 w-8 flex justify-center items-center"
              >
                <DialogLogo
                  color={action_type === "delete" ? "#F01F1F" : "#8E72FF"}
                  size={16}
                />
              </div>
              <p className="text-base leading-5 text-left font-[600]">
                {dialog_heading}
              </p>
            </div>
            <button onClick={handleFalseAction} className="h-5 w-5 text-center">
              <IoClose size={20} />
            </button>
          </div>
          <div className="flex justify-end items-center gap-2 mt-4">
            <button
              className="p-2 text-[#286043] text-sm leading-5 font-[600] border border-[#286043]"
              onClick={handleTrueAction}
            >
              {successBtnText}
            </button>
            <button
              onClick={handleFalseAction}
              className="p-2 text-[#286043] text-sm leading-5 font-[600] border border-[#286043]"
            >
              {failureBtnText}
            </button>
          </div>
        </div>
        {showNote && (
          <div className="flex justify-start items-center mt-4 gap-3 bg-gray-200 px-6 py-3">
            <p className="px-3 py-[6px] bg-[#FF9E44] text-white text-xs leading-4">
              NOTE
            </p>
            <p className="text-xs leading-4">
              You can find deleted actions in the archive section.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DialogComponent;

// bg-[${logo_bg_color}]
