import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import toast from "react-hot-toast";
import axiosInstance from "../utils/axiosInstance";
import dataSwitch from "../json/switch.json";
import demoDataForTable from "../json/tables/MyReports.json";
import { ReportProps } from "../interfaces/myReports";
import ReportDialogPopup from "../components/ReportDialogPopup";
import UserAvatar from "../components/userAvatar/UserAvatar";
import report_logo from "../assets/reports.png";

const tableHeader = [
  "report id",
  "report name",
  "advertiser",
  "completed on",
  "status",
  "download link",
];

const Reports: React.FC = () => {
  const [tableData, setTableData] = useState<ReportProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showDialogPopup, setShowDialogPopup] = useState<boolean>(false);
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    } else {
      getMyConnectorsData();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  const getMyConnectorsData = async () => {
    setLoading(true);
    if (dataSwitch.use_demo_data_for_reports_page) {
      setTableData(demoDataForTable);
      setLoading(false);
    } else {
      try {
        const response = await axiosInstance.get("/reports");
        if (response.status === 200) {
          // console.log(response); filled when wired with APIa requirement
        } else {
          throw new Error("Refresh the page and try again");
        }
      } catch (error: any) {
        toast.error(
          error.response?.data?.message ||
            "Error fetching brand safety data, please try again."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const tableHeadingLayout = {
    heading1: tableHeader[0],
    heading2: tableHeader[1],
    heading3: tableHeader[2],
    heading4: tableHeader[3],
    heading5: tableHeader[4],
    heading6: tableHeader[5],
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="reports" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <img
              src={report_logo}
              alt="opportunities logo"
              className="w-[17.5px] h-[17.5px]"
            />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">Reports</p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Generate your Reports
            </p>
          </div>
        </div>
        <ReportDialogPopup togglePopup={setShowDialogPopup} />
      </div>
    </div>
  );
};

export default Reports;
